import { useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from "@mui/material";
import { paths } from "src/paths";
import { useNavigate } from "react-router";
import { useAuthContext } from "src/contexts/auth-context";
import toast from "react-hot-toast";
import apiCalls from "src/api";
import PropTypes from "prop-types";
import * as Yup from "yup";

const validationSchema = Yup.object({
  title: Yup.string().max(255),
  icon_url: Yup.string().max(255),
  description: Yup.string().max(5000),
  calculator: Yup.number().min(1).required(),
});

const defaultInitialValues = {
  title: "",
  icon_url: "",
  description: "",
  calculator: 0,
  submit: null,
};

export const CreateCalculatorForm = (props) => {
  const { is_update = false, data = {}, update_callback } = props;
  data["submit"] = null;
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [calculatorType, setCalculatorType] = useState("");
  const [templateOptions, setTemplateOptions] = useState([
    {
      name: "Select Template",
      id: 0,
    },
  ]);
  const initialValues = is_update
    ? { submit: null, ...data }
    : defaultInitialValues;
  const selectedCalculator = data ? data.calculator.id : defaultInitialValues.calculator;
  const getTemplates = async () => {
    const res = await apiCalls.getCoreCalculators(user.id);
    if (res.status < 300) {
      setTemplateOptions((prevState) => {
        return [...prevState, ...res.data];
      });
    }
  };
  const createOrUpdate = (values, helpers = null) => {
    try {
      if (is_update) {
        let body = {};
        if (values.title) body["title"] = values.title;
        if (values.icon_url) body["icon_url"] = values.icon_url;
        if (values.description) body["description"] = values.description;
        apiCalls
          .patchCalculatorField(user.id, data.id, body)
          .then((res) => {
            if ("id" in res) {
              update_callback(values);
              toast.success("Calculator Updated successfully");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        apiCalls
          .createCalculator(values, user.id)
          .then((res) => {
            toast.success("Calculator created successfully");
            navigate(paths.calculators);
          })
          .catch((err) =>
            toast.error("Something went wrong. Please try again")
          );
      }
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, helpers) => {
      createOrUpdate(values, helpers);
    },
  });

  useEffect(() => {
    getTemplates();
  }, []);

  useEffect(() => {
    templateOptions.forEach((option) => {
      if (selectedCalculator === option.id) {
        console.log(option.name);
        setCalculatorType(option.name);
        return;
      }
    });
  }, [selectedCalculator, templateOptions]);

  return (
      <form onSubmit={formik.handleSubmit} {...props}>
        <Stack spacing={4}>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid xs={12} md={4}>
                  <Typography variant="h6">Basic details</Typography>
                </Grid>
                <Grid xs={12} md={8}>
                  <Stack spacing={3}>
                    <div><b>Calculator Type : </b>{calculatorType}</div>
                    <div>
                      <TextField
                        error={!!(formik.touched.title && formik.errors.title)}
                        fullWidth
                        helperText={formik.touched.title && formik.errors.title}
                        label="Title"
                        name="title"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.title}
                      />
                    </div>
                    <div>
                      <TextField
                        error={
                          !!(
                            formik.touched.icon_url && formik.errors.icon_url
                          )
                        }
                        fullWidth
                        label="Calculator Icon URL"
                        name="icon_url"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.icon_url}
                      ></TextField>
                    </div>
                    <div>
                      <TextField
                        error={
                          !!(
                            formik.touched.description &&
                            formik.errors.description
                          )
                        }
                        type="text"
                        fullWidth
                        multiline={true}
                        rows={5}
                        label="Description"
                        name="description"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.description}
                      ></TextField>
                    </div>
                    <div></div>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1}
          >
            {is_update ? (
              ""
            ) : (
              <Button color="inherit" onClick={() => navigate("/calculators")}>
                Cancel
              </Button>
            )}

            <Button
              type="submit"
              variant="contained"
              onClick={() => createOrUpdate(formik.values)}
            >
              {!is_update ? "Create" : "Update"}
            </Button>
          </Stack>
        </Stack>
      </form>
  );
};

CreateCalculatorForm.propTypes = {
  is_update: PropTypes.bool,
  data: PropTypes.object,
};
