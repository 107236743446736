import PropTypes from 'prop-types';
import { withAuthGuard } from 'src/hocs/with-auth-guard';
import { useSettings } from 'src/hooks/use-settings';
import { useSections } from './config';
import { HorizontalLayout } from './horizontal-layout';
import { VerticalLayout } from './vertical-layout';
import { useNavigate } from 'react-router';
import { useEffect  } from 'react';
import { paths } from 'src/paths';

export const Layout = withAuthGuard((props) => {
  const settings = useSettings();
  const sections = useSections();
  const navigate = useNavigate();

  const subs = JSON.parse(localStorage.getItem('subscription'));
  useEffect(() => {
    if (subs && subs.is_active) {
    
    } else {
      navigate(paths.pricingPlans)
    }
  }, [])
  

  if (settings.layout === 'horizontal') {
    return (
      <HorizontalLayout
        sections={sections}
        navColor={settings.navColor}
        {...props} />
    );
  }

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
      {...props} />
  );
});

Layout.propTypes = {
  children: PropTypes.node
};
