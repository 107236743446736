import { Grid, Stack, Card, CardContent, CardHeader, Typography, TextField, Divider, Button, Slider } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from 'yup';
// import { calculateHandler } from "./Util";

const validationSchema = Yup.object({
    initial_investment: Yup.number(),
    add_monry_annually: Yup.number(),
    s_p_500: Yup.number(),
    emrg_mkt_stock: Yup.number(),
    europe_stock: Yup.number(),
    reit: Yup.number(),
    commodities_tr: Yup.number(),
    us_agg: Yup.number(),
    high_yield: Yup.number(),
    emerging_bond: Yup.number(),
    muni: Yup.number()

});

const initialValues = {
    initial_investment: 10000,
    add_monry_annually: 0,
    s_p_500: 0,
    emrg_mkt_stock: 0,
    europe_stock: 0,
    reit: 0,
    commodities_tr: 0,
    us_agg: 0,
    high_yield: 0,
    emerging_bond: 0,
    muni: 0

}

const getChartContainer = (data) => {
    const { outcome,  median, standard_dev } = data;
    const { percentile_5, percentile_25, percentile_75, percentile_95 } = data;
    return <div class="result_block" style="display: none;">
        <hr />
        <h5>Outcome:
            <span id="Outcome">${outcome}</span>
        </h5>

        <h5>Median:
            <span id="Median">${median}</span>
        </h5>

        <h5>Standard Dev:
            <span id="Standard_Dev">${standard_dev}</span>
        </h5>
        <div class="sections d-flex flex-wrap" style="gap: 10px">
            <div class="flex-fill">
                <div class="result_val">
                    <h4 id="final_result5">{percentile_5}</h4>
                    <p>Percentile 5%</p>
                </div>
            </div>
            <div class="flex-fill">
                <div class="result_val">
                    <h4 id="final_result25">{percentile_25}</h4>
                    <p>Percentile 25%</p>
                </div>
            </div>
            <div class="flex-fill">
                <div class="result_val">
                    <h4 id="final_result75">{percentile_75}</h4>
                    <p>Percentile 75%</p>
                </div>
            </div>
            <div class="flex-fill">
                <div class="result_val">
                    <h4 id="final_result95">{percentile_95}</h4>
                    <p>Percentile 95%</p>
                </div>
            </div>
        </div>
        <figure class="card highchart_card highcharts-figure">
            <div id="container"></div>
            <p class="highcharts-description">
            </p>
        </figure>
        <section>
            <table class="w-100 table-bordered table-striped table">
                <thead>
                    <tr>
                        <th>Iterations</th>
                        <th>List of Iterations</th>
                    </tr>
                </thead>
                <tbody id="asset_class_stats_O12_O10012_values">

                </tbody>
            </table>

        </section>

    </div>
}

const RetirementCalculatorView = (props) => {
    const title = 'Monte Carlo Retirement Calculator';

    const [sliderValue, setSliderValue] = useState(50);
    const [showResult, setShowResult] = useState(false);
    const [result, setResult] = useState(null);
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, helpers) => {
            console.log(" =============== Submit ============", values)
            values['sliderValue'] = sliderValue
            // const data = calculateHandler(values)
            // setResult(data, () => {
                // setShowResult(true);
            // })
        }
    });
    const handleChange = (e) => {
        e.preventDefault();
        setSliderValue(e.target.value);
        console.log(e.target.value)
    }

    const getFields = (formik) => {
        return <>
            <form
              noValidate
              onSubmit={formik.handleSubmit}
            >
            <Grid container spacing={3} justifyContent={'center'}>
                <Grid sm={6} md={6} lg={6} p={2} >
                    <TextField
                        fullWidth
                        error={!!(formik.touched.initial_investment && formik.errors.initial_investment)}
                        helperText={formik.touched.initial_investment && formik.errors.initial_investment}
                        label="Initial Investment ($)"
                        name="initial_investment"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.initial_investment}
                    />
                </Grid>
                <Grid sm={6} md={6} lg={6} p={2}>
                    <TextField
                        fullWidth
                        error={!!(formik.touched.add_monry_annually && formik.errors.add_monry_annually)}
                        helperText={formik.touched.add_monry_annually && formik.errors.add_monry_annually}
                        label="Add Money Annually ($)"
                        name="add_monry_annually"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.add_monry_annually}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent={'center'}>
                <Grid sm={6} md={6} lg={6} p={2} textAlign={'center'} justifyContent={'center'}>

                    <label>{sliderValue} Years</label>
                    <Slider min={10} max={55} aria-label="Volume" value={sliderValue} onChange={handleChange} />
                </Grid>
            </Grid>
            <Grid container spacing={3} pb={2} justifyContent={'center'}>
                <Grid sm={3} md={3} lg={3} xs={3} textAlign={'end'} justifyContent={'center'}>

                    <label>10</label>
                </Grid>
                <Grid md={6} lg={6} xs={6}></Grid>
                <Grid md={3} lg={3} xs={3} justifyContent={'center'}>

                    <label>55</label>
                </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent={'center'}>
                <Grid sm={6} md={6} lg={6} p={2} >
                    <TextField
                        fullWidth
                        error={!!(formik.touched.s_p_500 && formik.errors.s_p_500)}
                        helperText={formik.touched.s_p_500 && formik.errors.s_p_500}
                        label="S&P 500 (%)"
                        name="s_p_500"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.s_p_500}
                    />
                </Grid>
                <Grid sm={6} md={6} lg={6} p={2}>
                    <TextField
                        fullWidth
                        error={!!(formik.touched.emrg_mkt_stock && formik.errors.emrg_mkt_stock)}
                        helperText={formik.touched.emrg_mkt_stock && formik.errors.emrg_mkt_stock}
                        label="Em Stock (%)"
                        name="emrg_mkt_stock"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.emrg_mkt_stock}
                    />
                </Grid>
                <Grid sm={6} md={6} lg={6} p={2} >
                    <TextField
                        fullWidth
                        error={!!(formik.touched.europe_stock && formik.errors.europe_stock)}
                        helperText={formik.touched.europe_stock && formik.errors.europe_stock}
                        label="Eur Stock (%)"
                        name="europe_stock"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.europe_stock}
                    />
                </Grid>
                <Grid sm={6} md={6} lg={6} p={2} >
                    <TextField
                        fullWidth
                        error={!!(formik.touched.reit && formik.errors.reit)}
                        helperText={formik.touched.reit && formik.errors.reit}
                        label="REIT (%)"
                        name="reit"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.reit}
                    />
                </Grid>
                <Grid sm={6} md={6} lg={6} p={2} >
                    <TextField
                        fullWidth
                        error={!!(formik.touched.commodities_tr && formik.errors.commodities_tr)}
                        helperText={formik.touched.commodities_tr && formik.errors.commodities_tr}
                        label="Commodities (%)"
                        name="commodities_tr"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.commodities_tr}
                    />
                </Grid>
                <Grid sm={6} md={6} lg={6} p={2} >
                    <TextField
                        fullWidth
                        error={!!(formik.touched.us_agg && formik.errors.us_agg)}
                        helperText={formik.touched.us_agg && formik.errors.us_agg}
                        label="US Agg (%)"
                        name="us_agg"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.us_agg}
                    />
                </Grid>
                <Grid sm={6} md={6} lg={6} p={2} >
                    <TextField
                        fullWidth
                        error={!!(formik.touched.high_yield && formik.errors.high_yield)}
                        helperText={formik.touched.high_yield && formik.errors.high_yield}
                        label="High Yield (%)"
                        name="high_yield"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.high_yield}
                    />
                </Grid>
                <Grid sm={6} md={6} lg={6} p={2} >
                    <TextField
                        fullWidth
                        error={!!(formik.touched.emerging_bond && formik.errors.emerging_bond)}
                        helperText={formik.touched.emerging_bond && formik.errors.emerging_bond}
                        label="EM Bond (%)"
                        name="emerging_bond"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.emerging_bond}
                    />
                </Grid>
                <Grid sm={6} md={6} lg={6} p={2} >
                    <TextField
                        fullWidth
                        error={!!(formik.touched.muni && formik.errors.muni)}
                        helperText={formik.touched.muni && formik.errors.muni}
                        label="Muni (%)"
                        name="muni"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        value={formik.values.mun100i}
                    />
                </Grid>

            </Grid>
            <Grid container justifyContent={'center'}>
                <Button
                    sx={{
                        backgroundColor: "success.main",
                        "&:hover": {
                            backgroundColor: "success.dark",
                        },
                    }}
                    type="submit"
                    variant="contained">Calculate</Button>
            </Grid>
            </form>
        </>
    }

    return <Grid padding={4} sx={{ width: '100%' }}>
        <Card>
            <CardHeader title={title}>
            </CardHeader>
            <CardContent>
                {getFields(formik)}
                {showResult ? getChartContainer(result) : ''}
            </CardContent>
        </Card>
    </Grid>
}

export default RetirementCalculatorView;