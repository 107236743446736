import { getCachedCustomerId } from "src/utils/cache";
import { appConfig } from "../config";
// import { reqAnonGet, reqAnonPost, reqGet, reqPatch, reqPost, getAuthHeaders } from "../utils/requests";
import { reqPatch, reqAnonPost, reqDelWithResponse, reqPutWithResponse, reqGetWithResponse, reqPost, reqPatchWithResponse, reqGet } from "../utils/requests";

const publicApiBaseUrl = `${appConfig.backendApiHostname}/api/pub`;
const customerApiBaseUrl = () => `${appConfig.backendApiHostname}/api/cus/${getCachedCustomerId()}`;

const apiCalls = {
  // Auth Endpoints
  login: async (data) => await reqAnonPost(`${publicApiBaseUrl}/sign-in/`, data),
  register: async (data) => await reqAnonPost(`${publicApiBaseUrl}/sign-up/`, data),
  forgotPassword: async (data) => await reqAnonPost(`${publicApiBaseUrl}/reset-password/`, data),
  resetPassword: async (data) => await reqAnonPost(`${publicApiBaseUrl}/verify/reset-pass/`, data),
  // getCalculators: async(accessKey) => reqAnonPost(`${publicApiBaseUrl}/calculators/details-admin/${accessKey}`),
  getCalculators: async (accessKey) => reqGet(`${customerApiBaseUrl()}/calculators/details-admin/${accessKey}`),
  updateProfile: async (data, pk = null) => await reqPutWithResponse(`${customerApiBaseUrl()}/users/${pk}/`, data),
  getUser: async (pk = null) => await reqGetWithResponse(`${customerApiBaseUrl()}/users/${pk}/`),
  getCustomerPermissions: async (pk = null) => await reqGetWithResponse(`${customerApiBaseUrl()}/permissions/`),
  // Subscription Endpoints
  getSubsciptions: async (pk = null) => await reqGetWithResponse(`${customerApiBaseUrl()}/subscription/get-user-subscription/`),
  createSubscriptions: async (data, pk = null) => await reqPost(`${customerApiBaseUrl()}/subscription/create-subscription/`, data),
  createClientKey: async (data, pk = null) => reqPost(`${customerApiBaseUrl()}/subscription/create-client-key/`, data),
  createClientKeyV2: async (data, pk = null) => reqPost(`${customerApiBaseUrl()}/subscription/create-client-key-v2/`, data),
  redeemReferenceToken: async (data, pk = null) => reqPost(`${customerApiBaseUrl()}/subscription/redeem-discount-coupon/${data.token}/`, data),
  cancelClientKey: async (sub_id, pk = null) => reqDelWithResponse(`${customerApiBaseUrl()}/subscription/create-client-key/?sub_id=${sub_id}`),
  getPlans: async (pk = null) => reqGetWithResponse(`${customerApiBaseUrl()}/subscription/get-plans/`),
  createPlanPrice: async (data, pk = null) => reqPost(`${customerApiBaseUrl()}/subscription/plan-prices/`, data),
  patchPlanField: async (data, plan_id, pk = null) => reqPatch(`${customerApiBaseUrl()}/subscription/plan/${plan_id}/`, data),
  getProratedAmount: async (subscription_id, pk = null) => reqGetWithResponse(`${customerApiBaseUrl()}/subscription/get-prorated-amount/${subscription_id}/`),
  createPlanUpgradeSession: async (subscription_id, data, pk = null) => reqPost(`${customerApiBaseUrl()}/subscription/create-plan-upgrade-session/${subscription_id}/`, data),
  generateCustomLinks: async (data, pk = null) => reqPost(`${customerApiBaseUrl()}/custom-signup-link/`, data),
  getAllTokens: async (nextUrl = `${customerApiBaseUrl()}/custom-signup-link/`) => reqGetWithResponse(nextUrl),
  updateCustomLink: async (id, data) => reqPatchWithResponse(`${customerApiBaseUrl()}/custom-signup-link/${id}/`, data),
  createDiscountCoupon: async (data) => reqPost(`${customerApiBaseUrl()}/subscription/discount-coupon/`, data),


  // Calculator Endpoints
  getUserCalculator: async (pk = null, nextUrl = `${customerApiBaseUrl()}/calculators/`) => {
    return reqGetWithResponse(nextUrl);
  },
  getArchivedCalculators: async (pk = null, nextUrl = `${customerApiBaseUrl()}/calculators/?is_archive=true`) => {
    return reqGetWithResponse(nextUrl);
  },
  getCoreCalculators: async (pk = null) => reqGetWithResponse(`${customerApiBaseUrl()}/calculators/core-calculator/list/`),
  createCalculator: async (data, pk = null) => reqPost(`${customerApiBaseUrl()}/calculators/`, data),
  updateCalculator: async (data, pk, id) => reqPutWithResponse(`${customerApiBaseUrl()}/calculators/${id}/`, data),
  deleteCalculator: async (id) => reqDelWithResponse(`${customerApiBaseUrl()}/calculators/${id}/`),
  patchCalculatorField: async (pk = null, id, data) => reqPatch(`${customerApiBaseUrl()}/calculators/${id}/`, data),
  // Analytics
  getCalculatorsCounts: async (pk = null) => reqGetWithResponse(`${customerApiBaseUrl()}/analytics/metrics/calculator-count/`),
  getDeviceType: async (pk = null) => reqGetWithResponse(`${customerApiBaseUrl()}/analytics/metrics/device-type/`),
  getBrowserType: async (pk = null) => reqGetWithResponse(`${customerApiBaseUrl()}/analytics/metrics/browser-type/`),
  getUsageFrequency: async (pk = null, params) => reqGetWithResponse(`${customerApiBaseUrl()}/analytics/metrics/usage-frequency/?${params}`),
  getAllCustomers: async () => reqGetWithResponse(`${customerApiBaseUrl()}/customers/get-all/`),
  updateCustomer: async (id, data) => reqPatchWithResponse(`${customerApiBaseUrl()}/customers/${id}/`, data),
  getDomainInfo: async (id, nextUrl = `${customerApiBaseUrl()}/analytics/domain-info/list/${id}/`) => reqGetWithResponse(nextUrl),
  getUsageDetails: async (id) => reqGetWithResponse(`${customerApiBaseUrl()}/analytics/usage-interactions/list/${id}/`),
  createFreeUser: async (data) => reqPost(`${customerApiBaseUrl()}/free-user-email/`, data),
  getAllFreeUsers: async () => reqGetWithResponse(`${customerApiBaseUrl()}/free-user-email/`),
  getFreeUser: async (id) => reqGetWithResponse(`${customerApiBaseUrl()}/free-user-email/${id}/`),
  patchFreeUser: async (id, data) => reqPatchWithResponse(`${customerApiBaseUrl()}/free-user-email/${id}/`, data),
  deleteFreeUser: async (id) => reqDelWithResponse(`${customerApiBaseUrl()}/free-user-email/${id}/`),
  // uploadUsers: async (data)=> await reqPost(`${apiBaseUrl}/users/import/`, data, {
  //   ...getAuthHeaders(),
  //   "Content-Type": "multipart/form-data",
  // }),
};

export default apiCalls;
