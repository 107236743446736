import PropTypes from 'prop-types';
// import { subDays, subHours } from 'date-fns';
// import ArrowRightIcon from '@untitled-ui/icons-react/build/esm/ArrowRight';
// import Edit02Icon from '@untitled-ui/icons-react/build/esm/Edit02';
// import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
// import Image01Icon from '@untitled-ui/icons-react/build/esm/Image01';
// import { CircularProgress } from "@mui/material";
import DotsHorizontalIcon from '@untitled-ui/icons-react/build/esm/DotsHorizontal';
import { useNavigate } from 'react-router';
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown';
import {
  Grid,
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  SvgIcon,
  TablePagination,
  Typography,
  Button,
  ButtonGroup,
  Modal,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Popper,
  Grow,
  Stack
} from '@mui/material';
import { Scrollbar } from 'src/components/scrollbar';
import { useEffect, useState, useRef } from 'react';
import apiCalls from 'src/api';
import { useAuthContext } from "src/contexts/auth-context";
import { ItemList } from './item-list';
import { paths } from 'src/paths';
import { CreateCalculatorModal } from './create-calculator-modal';
import { useCallback } from 'react';

const now = new Date();

/* Example
const items = [{
    id: 1, 
    type: 'folder', 
    title:'Investment Calculator',
    sub_title: 'Monte carlo',
    description: 'Jack and gill sat on a heel. Humpty dumpty sat on  wall.Jack and gill sat on a heel. Humpty dumpty sat on  wall.Jack and gill sat on a heel. Humpty dumpty sat on  wall.Jack and gill sat on a heel. Humpty dumpty sat on  wall.Jack and gill sat on a heel. Humpty dumpty sat on  wall.Jack and gill sat on a heel. Humpty dumpty sat on  wall.Jack and gill sat on a heel. Humpty dumpty sat on  wall.Jack and gill sat on a heel. Humpty dumpty sat on  wall.Jack and gill sat on a heel. Humpty dumpty sat on  wall.Jack and gill sat on a heel. Humpty dumpty sat on  wall.Jack and gill sat on a heel. Humpty dumpty sat on  wall.Jack and gill sat on a heel. Humpty dumpty sat on  wall.Jack and gill sat on a heel. Humpty dumpty sat on  wall.Jack and gill sat on a heel. Humpty dumpty sat on  wall.',
    isPublic: true, 
    isFavorite: true, 
    created_at: new Date(), 
    // extension: 'pdf'
}] */

const tabs = [
  {
    label: 'All',
    value: 'all'
  },
  {
    label: 'Accepts Marketing',
    value: 'hasAcceptedMarketing'
  },
  {
    label: 'Prospect',
    value: 'isProspect'
  },
  {
    label: 'Returning',
    value: 'isReturning'
  }
];

const options = [
  'Action',
  'Create Calculator',
  'Archive'
];

const sortOptions = [
  {
    label: 'Last update (newest)',
    value: 'updatedAt|desc'
  },
  {
    label: 'Last update (oldest)',
    value: 'updatedAt|asc'
  },
  {
    label: 'Total orders (highest)',
    value: 'orders|desc'
  },
  {
    label: 'Total orders (lowest)',
    value: 'orders|asc'
  }
];
const title = 'Calculators';
const description = 'Monte Carlo';
const name = '';

export const CustomTablePage = () => {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [next, setNext] = useState('');
  const [previous, setPrevious] = useState('');
  const  { user } = useAuthContext();
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openButton, setOpenButton] = useState(false);
  const handleMenuItemClick = useCallback((index) => {
    setSelectedIndex(index);
    setOpenButton(false);
    if (index === 1) {
      handleOpen();
    } else if(index === 2) {
      navigate('/calculators/archive')
    }
  }, []);

  const handleToggle = useCallback(() => {
    setOpenButton((prevOpen) => !prevOpen);
  }, []);

  const getCalculators = async (newPage) => {
    let res;
    let isPrevious = newPage < page ? true : false;
    if(next && !isPrevious) {
      res = await apiCalls.getUserCalculator(user.id, next); 
    } else if (isPrevious) {
      res = await apiCalls.getUserCalculator(user.id, previous);
    }else {
      res = await apiCalls.getUserCalculator(user.id);
    }
    
    if(res.status < 300) {
      const  seq = res.data.results.map((calculator) => {
        calculator.type= 'folder';
        calculator.isPublic= true // TODO ADD in backend
        calculator.isFavorite = false;
        calculator.created_at = new Date(calculator.created_at);
        calculator.image = 'https://static.easify.xyz/lp/assets/products/product-1.png'
        return calculator
      });
      setCount(res.data.count);
      setNext(res.data.next);
      setPrevious(res.data.previous);
      setItems(seq);
      if (typeof newPage !== 'boolean') {
        setPage(newPage);
      }
      // if(res.data.previous) {
      //   setPage(res.data.previous.)
      // }
    }
  }
  const checkValues = () =>{
    
  }
  useEffect(() => {
    if(next === '') {
      getCalculators(false);
    }
    // checkValues();
  }, [])
  return <>
    {/* <MoreMenu /> */}
    <Stack sx={{
      padding: '20px'
    }} alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={1}>
            <Typography variant="h4">
              {title}
            </Typography>
            <Stack>
            <ButtonGroup ref={anchorRef} variant="contained">
            <Button onClick={() => handleMenuItemClick(selectedIndex)}>
              {options[selectedIndex]}
            </Button>
            <Button
              onClick={handleToggle}
              size="small"
              sx={{ backgroundColor: 'primary.dark' }}
            >
              <SvgIcon>
                <ChevronDownIcon />
              </SvgIcon>
            </Button>
          </ButtonGroup>
          <Popper
            anchorEl={anchorRef.current}
            open={openButton}
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom'
                        ? 'center top'
                        : 'center bottom'
                    }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      {options.map((option, index) => (
                        <MenuItem
                          disabled={index === 0}
                          key={option}
                          onClick={() => handleMenuItemClick(index)}
                          selected={index === selectedIndex}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
            </Stack>
    </Stack>
    <Divider />
    <Grid container
      spacing={3} padding={4}>
        <Grid item xs={12} md={12} sm={12}>
        <Card>
      {/* <Tabs
        indicatorColor="primary"
        scrollButtons="auto"
        textColor="primary"
        value="all"
        sx={{ px: 3 }}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider /> 
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        gap={2}
        sx={{ p: 3 }}
      >
        <OutlinedInput
          placeholder="Search calculators"
          startAdornment={(
            <InputAdornment position="start">
              <SvgIcon>
                <SearchMdIcon />
              </SvgIcon>
            </InputAdornment>
          )}
          sx={{ flexGrow: 1 }}
        />
        <TextField
          label="Sort By"
          name="sort"
          select
          SelectProps={{ native: true }}
        >
          {sortOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField>
      </Stack>*/}
      <CardHeader
        // action={(
        //   <IconButton>
        //     <SvgIcon>
        //       <DotsHorizontalIcon />
        //     </SvgIcon>
        //   </IconButton>
        // )}
        title="Created Calculators"
      />
      <Scrollbar>
        <ItemList items={items} />
      </Scrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={(event, newPage) => {  getCalculators(newPage) }}
        onRowsPerPageChange={() => {  }}
        page={page}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
      />
    </Card>
        </Grid>
      </Grid>
      <CreateCalculatorModal open={open} onClose={handleClose} />
  </>
}
;

CustomTablePage.propTypes = {
  caption: PropTypes.string,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  // From Dropzone
  accept: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string.isRequired).isRequired),
  disabled: PropTypes.bool,
  getFilesFromEvent: PropTypes.func,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  preventDropOnDocument: PropTypes.bool
};


// CustomTablePage.defaultProps = {
//   caption: PropTypes.string,
//   files: PropTypes.array,
//   onRemove: PropTypes.func,
//   onRemoveAll: PropTypes.func,
//   onUpload: PropTypes.func,
//   // From Dropzone
//   accept: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string.isRequired).isRequired),
//   disabled: PropTypes.bool,
//   getFilesFromEvent: PropTypes.func,
//   maxFiles: PropTypes.number,
//   maxSize: PropTypes.number,
//   minSize: PropTypes.number,
//   noClick: PropTypes.bool,
//   noDrag: PropTypes.bool,
//   noDragEventsBubbling: PropTypes.bool,
//   noKeyboard: PropTypes.bool,
//   onDrop: PropTypes.func,
//   onDropAccepted: PropTypes.func,
//   onDropRejected: PropTypes.func,
//   onFileDialogCancel: PropTypes.func,
//   preventDropOnDocument: PropTypes.bool
// };

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};