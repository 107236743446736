import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Switch,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
  SvgIcon,
  Breadcrumbs,
  Container,
  Link,
  Box,
  Avatar,
  Chip

} from '@mui/material';
import { RouterLink } from 'src/components/router-link';
import { paths } from 'src/paths';
import { wait } from 'src/utils/wait';
import ArrowLeftIcon from '@untitled-ui/icons-react/build/esm/ArrowLeft';
import { BreadcrumbsSeparator } from 'src/components/breadcrumbs-separator';
import { useState, useEffect } from 'react';
import apiCalls from "src/api";

export const ProfileForm = (props) => {
  const { ...other } = props;
  // TODO call get user API
  const [ customer, setCustomer ] = useState(JSON.parse(localStorage.getItem('app.user')))
  const breadcrumbs =  ['profile']
  const title = customer.email
  let country_code = ''
  if(!customer.phone_number) {
    country_code = '1'
  } else {
    country_code = customer.phone_number.country_code
  }
  const initialValues = {
    id: customer.id,
    first_name: customer.first_name || '',
    last_name: customer.last_name || '',
    email: customer.email || '',
    country_code: `+${country_code}` || '',
    phone_number: customer.phone_number?.number || '',
    phone: customer.phone || '',
    last_login: customer.last_login || '',
    date_joined: customer.date_joined || '',
    submit: null
  }
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      first_name: Yup
        .string()
        .max(255)
        .required('First Name is required'),
      last_name: Yup.string()
      .max(255)
      .required('First Name is required'),
      email: Yup
        .string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      phone: Yup.string().max(15),
    }),
    onSubmit: async (values, helpers) => {
      try {
        let body = JSON.parse(JSON.stringify(values))
        body.phone = body.country_code.trim() + '-' + body.phone_number
        delete body.country_code
        delete body.phone_number

        // NOTE: Make API request
        const res = await apiCalls.updateProfile(body, customer.id);
        if (res.status < 300) {   
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          toast.success('Profile Updated Successfully!');
          localStorage.setItem("app.user", JSON.stringify(res.data))
        } else {
          toast.error("Updating Action Failed");
        }
        
      } catch (err) {
        toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const fetchCustomer = async () => {
    let res = await apiCalls.getUser(customer.id);
    if(res.status === 200) {
      setCustomer(res.data)
    }
  }

  useEffect(() => {
    fetchCustomer()
  }, [])

  return (
    <>
    <Container maxWidth="lg">
    
          <Stack spacing={3}>
            {/* {!breadcrumbs && ( */}
              <div>
                <Link
                  color="text.primary"
                  component={RouterLink}
                  href={paths.profile}
                  sx={{
                    alignItems: 'center',
                    display: 'inline-flex'
                  }}
                  underline="hover"
                >
                  <SvgIcon sx={{ mr: 1 }}>
                    <ArrowLeftIcon />
                  </SvgIcon>
                  <Typography variant="subtitle2">
                    Back
                  </Typography>
                </Link>
              </div>
            {/* )} */}
            <div>
              <Grid container spacing={3}>
                <Grid
                  xs={1}
                  md={1}>
                      <Avatar
                        src={'assets/avatars/avatar-alcides-antonio.png'}
                        sx={{ width: 60, height: 60 }}
                        style={{marginTop: '15px'}}
                      >
                      </Avatar>
                </Grid>
                <Grid
                  xs={10}
                  md={10}
                >
                  <Typography variant="h4" style={{paddingTop: '10px'}}>
                    {title}
                  </Typography>
                  {/* user_id: <Chip label={customer.id}></Chip> */}
                </Grid>
              </Grid>
            </div>
            {breadcrumbs && (
              <div>
                <Breadcrumbs separator={<BreadcrumbsSeparator />}>
                  {breadcrumbs.map((item, index) => {
                    const isLast = breadcrumbs.length - 1 === index;

                    if (isLast) {
                      return (
                        <Typography
                          color="text.secondary"
                          key={index}
                          variant="subtitle2"
                        >
                          {item.title}
                        </Typography>
                      );
                    }

                    return (
                      <Link
                        color="text.primary"
                        component={RouterLink}
                        href={item.href}
                        key={index}
                        variant="subtitle2"
                      >
                        {item.title}
                      </Link>
                    );
                  })}
                </Breadcrumbs>
              </div>
            )}
          </Stack>
          <form
      onSubmit={formik.handleSubmit}
      {...other}>
      <Card>
        <CardHeader title="Edit Customer" />
        <CardContent sx={{ pt: 0 }}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              xs={12}
              md={6}
            >
              <TextField
                error={!!(formik.touched.first_name && formik.errors.first_name)}
                fullWidth
                helperText={formik.touched.first_name && formik.errors.first_name}
                label="Fisrt Name"
                name="first_name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.first_name}
              />
            </Grid>
             <Grid
              xs={12}
              md={6}
            >
                <TextField
                error={!!(formik.touched.last_name && formik.errors.last_name)}
                fullWidth
                helperText={formik.touched.last_name && formik.errors.last_name}
                label="Last Name"
                name="last_name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.last_name}
              />
              
            </Grid>
            {/*<Grid
              xs={12}
              md={6}
            >
              <TextField
                error={!!(formik.touched.country && formik.errors.country)}
                fullWidth
                helperText={formik.touched.country && formik.errors.country}
                label="Country"
                name="country"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.country}
              />
            </Grid>
            <Grid
              xs={12}
              md={6}
            >
              <TextField
                error={!!(formik.touched.state && formik.errors.state)}
                fullWidth
                helperText={formik.touched.state && formik.errors.state}
                label="State/Region"
                name="state"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.state}
              />
            </Grid>
            <Grid
              xs={12}
              md={6}
            >
              <TextField
                error={!!(formik.touched.address1 && formik.errors.address1)}
                fullWidth
                helperText={formik.touched.address1 && formik.errors.address1}
                label="Address 1"
                name="address1"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address1}
              />
            </Grid>
            <Grid
              xs={12}
              md={6}
            >
              <TextField
                error={!!(formik.touched.address2 && formik.errors.address2)}
                fullWidth
                helperText={formik.touched.address2 && formik.errors.address2}
                label="Address 2"
                name="address2"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.address2}
              />
            </Grid> */}
            <Grid
              xs={2}
              md={2}
            >
              <TextField
                error={!!(formik.touched.country_code && formik.errors.country_code)}
                fullWidth
                helperText={formik.touched.country_code && formik.errors.country_code}
                label="Country Code"
                name="country_code"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.country_code}
              />

            </Grid>
            <Grid
              xs={10}
              md={4}
            >
              <TextField
                error={!!(formik.touched.phone && formik.errors.phone_number)}
                fullWidth
                helperText={formik.touched.phone && formik.errors.phone_number}
                label="Phone number"
                name="phone_number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.phone_number}
              />
            </Grid>
            <Grid
                xs={12}
                md={6}
            >
                <TextField
                error={!!(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email address"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.email}
                disabled={true}
              />
            </Grid>
          </Grid>
          {/* <Stack
            divider={<Divider />}
            spacing={3}
            sx={{ mt: 3 }}
          >
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={3}
            >
              <Stack spacing={1}>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                >
                  Make Contact Info Public
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                >
                  Means that anyone viewing your profile will be able to see your contacts
                  details
                </Typography>
              </Stack>
              <Switch
                checked={formik.values.isVerified}
                color="primary"
                edge="start"
                name="isVerified"
                onChange={formik.handleChange}
                value={formik.values.isVerified}
              />
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              spacing={3}
            >
              <Stack spacing={1}>
                <Typography
                  gutterBottom
                  variant="subtitle1"
                >
                  Available to hire
                </Typography>
                <Typography
                  color="text.secondary"
                  variant="body2"
                >
                  Toggling this will let your teammates know that you are available for
                  acquiring new projects
                </Typography>
              </Stack>
              <Switch
                checked={formik.values.hasDiscount}
                color="primary"
                edge="start"
                name="hasDiscount"
                onChange={formik.handleChange}
                value={formik.values.hasDiscount}
              />
            </Stack>
          </Stack> */}
        </CardContent>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row'
          }}
          flexWrap="wrap"
          spacing={3}
          sx={{ p: 3 }}
        >
          <Button
            disabled={formik.isSubmitting}
            type="submit"
            variant="contained"
          >
            Update
          </Button>
          {/* <Button
            color="inherit"
            component={RouterLink}
            disabled={formik.isSubmitting}
            onClick={() => navigate(paths.profile)}
          >
            Cancel
          </Button> */}
        </Stack>
      </Card>
    </form>
        </Container>
    </>
  );
};

// ProfileForm.propTypes = {
//   customer: PropTypes.object.isRequired
// };
