import PropTypes from 'prop-types';
import { FileIcon } from 'src/components/file-icon';

export const ItemIcon = (props) => {
  const { type, extension, sx={}, className='' } = props;
  // <img src="https://static.easify.xyz/lp/assets/icons/icon-folder.svg" />
  return type === 'folder'
    ? <img style={sx} className={className} src={'/assets/icons/sheet.png'} height={50} width={50} />
    : <FileIcon extension={extension} />;
};

ItemIcon.propTypes = {
  extension: PropTypes.string,
  type: PropTypes.oneOf(['file', 'folder']).isRequired
};
