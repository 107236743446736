import { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const CACHE_KEY = 'app.global';

const restoreGlobalConfigFromCache = () => {
  let value = null;
  try {
    const restored = window.localStorage.getItem(CACHE_KEY);
    if (restored) {
      value = JSON.parse(restored);
    }
  } catch (err) {
    console.error(err);
  }
  return value;
};

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [globalConfig, setGlobalConfig] = useState({});
  const [pageTitle, setPageTitle] = useState("");

  useEffect(() => {
    const restored = restoreGlobalConfigFromCache();
    if (restored) {
      setGlobalConfig(restored);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem(CACHE_KEY, JSON.stringify(globalConfig));
    if (globalConfig.favicon) {
      let link = document.getElementById("favicon");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      } 
      link.href = globalConfig.favicon;
    }
  }, [globalConfig]);

  return (
    <GlobalContext.Provider
      value={{
        pageTitle,
        globalConfig,
        setPageTitle,
        setGlobalConfig
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const GlobalConsumer = GlobalContext.Consumer;

export const useGlobalContext = () => useContext(GlobalContext);
