export const paths = {
  index: "/dashboard",
  login: "/login",
  register: "/register",
  forgotPassword: "/forgot-password",
  resetPassword:"/reset-password",
  profile: "/profile",
  editProfile:"/edit-profile",
  calculators: "/calculators",
  createCalculator: "/calculators/create",
  calculatorsDetails:"/calculators/:id/details",
  calculatorsArchive:"/calculators/archive",
  investmentCalculatorWidget:"/calculators/investment-calculator",
  retirementCalculatorWidget:"/calculators/retirement-calculator",
  floatingWidget: '/calculators/floating-widget',
  retirementCalculator:'/calculators/retirement-calculator-view',
  subscriptionsConfig: '/subscriptions/config',
  customersList: '/customers/list',
  FreeUsersList: '/free/users/list',
  upgradePlanSuccess: '/subscription/upgrade-plan/:id/payment-success',
  upgradePlanCancel: '/subscription/upgrade-plan/:id/payment-cancel',
  pricingPlans: "/pricing-plans",
  privacyPolicy: "/privacy-policy",
  customSignupLinks: '/subscriptions/custom-signup-links',
  401: "/401",
  404: "/404",
  500: "/500",
};
