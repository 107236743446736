import { Dialog, DialogTitle, DialogContent, Box, Button, Card, CardContent, CardHeader, Chip, Grid, Stack, SvgIcon, Typography, CardActions } from "@mui/material";
import { toast } from "react-hot-toast";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { irBlack } from "react-syntax-highlighter/dist/esm/styles/hljs";
import DoneIcon from '@mui/icons-material/Done';
import { useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import apiCalls from "src/api";
const styles = {code: {
    color: '#2a9921',
    'background-color': '#f0f0f0',
    padding: '2px 4px',
    'font-family': "'Courier New', monospace",
}, floating: {
  position: 'fixed',
  bottom: '50px',
  left: '50px',
  width: '50px',
  height: '50px',
  'background-color': '#fff',
  'border-radius': '50%',
  'box-shadow': '0 5px 5px rgba(0,0,0,0.1)'
}
}

const IntegrationTab = (props) => {
  const domainName = window.location.hostname;
  const {user_permissions =[], updateConfig=() => {}, 
  data={'id':'NA', calculator: {default_config: {component: "RetirementCalculator"}}}} = props
  const isFloatingWidget = (-1 <  user_permissions.findIndex(x => x === 'can_view_widget_integration_code'))
  const isGoogleAnalytics = (-1 < user_permissions.findIndex(x => x === 'can_edit_google_analytics_integration_code'))
  const [googleAnalyticsCode, setGoogleAnalyticsCode] = useState(data.config["ga_code"] || "// Your code goes here")
  const [isEdited, setIsEdited] = useState();
  const [config, setConfig] = useState();
  const urls = {
    "InvestmentCalculator": `https://${domainName}/cals-build/investment-bundle.js`,
    "RetirementCalculator": `https://${domainName}/cals-build/montecarlo-retirement.js`,
    "HomeLoanCalculator": `https://${domainName}/cals-build/home-loan-bundle.js`,
    "BusinessLoanCalculator": `https://${domainName}/cals-build/business-loan-bundle.js`,
    "AdvisoryFeeComparisonCalculator": `https://${domainName}/cals-build/fee-comparison-bundle.js`
  }
  const blockContent = `<!-- Please copy the below code and paste in your website html -->
  <div id="calculator-container">
  </div>
  <script>
    var democratyzAccessKey = '${data.id}';
  </script>
  <script src="${urls[data.calculator.default_config.component]}" crossorigin="anonymous"></script>`

  const generateWidgetHTML = (containerCss) => {
    let css = containerCss.split(' ')
    return `<!-- Please copy the below code and paste in your website html -->
    <div style="${css[0]}" class="democratyz-wrap">
    <div class="democratyz-panel" style="${css[1]}">
        <div id="calculator-container">
        </div>
    </div>
    <div class="democratyz-badge">
        <img id='democratyz-floating-widget-image-tag' src="https://app.democratyz.io/calculator.png">
    </div>
</div>
<script>var democratyzAccessKey = '${data.id}';
</script>
<script src="${urls[data.calculator.default_config.component]}"></script>`
  }

  const iframeBlockContent = `<!-- Please copy the below code and paste in your website html -->
    <iframe
      srcdoc="
        <div id='calculator-container'>
        </div>
        <script>
            var democratyzAccessKey = '${data.id}';
        </script>
        <script src='${urls[data.calculator.default_config.component]}' crossorigin='anonymous'></script>
    "
      width="100%"
      height="500px" 
      style="border: none; overflow-x: hidden"
    >
    </iframe>`;
  
  
  const handleCopy = (content) => {
    document.execCommand(content)
    toast.success("Text copied to clipboard");
  }
  const [selectedPosition, setSelectedPosition] =  useState('Left Bottom')
  const availablePositionCSS = {
    'Left Bottom': 'bottom:40px;left:40px; left:0px;',
    'Right Bottom':'bottom:40px;right:40px; right:0px;',
  }
  const getFloatingStyle = (position) => {
    let css = `z-index:9999999;position:fixed;` + availablePositionCSS[position]
    return css
  }
  const [floatingWidgetContent, setFloatingWidgetContent] = useState(generateWidgetHTML(getFloatingStyle(selectedPosition)))
  const handleClick = (currentSelected) => {
    setSelectedPosition(currentSelected);
    setFloatingWidgetContent(generateWidgetHTML(getFloatingStyle(currentSelected)))
  }
  const [ isCopied, setIsCopied] = useState([false, false]);

  const updateGoogleAnalyticsCode = (val) => {
    if(val.trim() === '' || val === '// Your code goes here') {
      setIsEdited(false);
    } else {
      setIsEdited(true);
    }
    setGoogleAnalyticsCode(val);
  }

  const [isExampleOpen, setIsExampleOpen] = useState(false);

  const isGACodeValid = (ga_code) => {
    var gaCodeRegex = /<script\s+async\s+src="https:\/\/www\.googletagmanager\.com\/gtag\/js\?id=[\w-]+"><\/script>\s*<script>\s*window\.dataLayer\s*=\s*window\.dataLayer\s*\|\|\s*\[\]\s*;\s*function\s+gtag\(\)\s*{dataLayer\.push\(arguments\);}\s*gtag\('js',\s*new\s+Date\(\)\);\s*gtag\('config',\s*'[\w-]+'\);\s*<\/script>/;
    return gaCodeRegex.test(ga_code)
  }

  const saveGoogleAnalyticsCode = () => {
    // if(newValue.label) {
      // if(isGACodeValid(googleAnalyticsCode)) {
        const config = {...data.config};
        config['ga_code'] = googleAnalyticsCode;

        apiCalls.patchCalculatorField(data.customer, data.id,{config}).then(resp => {          
            toast.success("Code Updated Successfully!");
            updateConfig(config);
            setIsEdited(false);
        }).catch(err => {
            console.log(err)
        });
    //   }
    // else {
    //     toast.error("Invalid input for Google Analytics code. Please check example")
    // }
}

  const copyClipboard = async (index, text) => {
    // Do not allow copy for non premium plan
    if(index === 1 && !isFloatingWidget) {
      return
    }
    await navigator.clipboard.writeText(text);
    setIsCopied(prev => { 
      prev.forEach((v, i) => {
        if(index === i) {
          prev[index]= true;
        } else {
          prev[i] = false;
        }
      });
      return [...prev]
    })
  }

  const getChips = () => {
    return Object.keys(availablePositionCSS).map((x) => {
      if(selectedPosition === x) {
        return <Chip
          label={x}
          onClick={() => handleClick(x)}
          deleteIcon={<DoneIcon />}
        />
      }else { 
        return <Chip
        label={x}
        onClick={() => handleClick(x)}
        variant="outlined"
      />
      } 
    })
  }
  return (
    <>
    
      <Stack sx={
        {
          width: '100%'
        }
      } justifyContent={'center'}>
        <Grid
        >
          <Grid justifyContent={'center'} item xs={12} md={12} sm={12}>
            <Card>
              <CardHeader title={'Integration Code'} variant="h4" action={
                (
                  isCopied[0] ?
                  <Tooltip title="Copied!" sx={{color: '#16B364', cursor:'pointer'}}>
                    <SvgIcon onClick={() => copyClipboard(0, blockContent)} sx={{ color: '#16B364' }}> <ContentCopyOutlinedIcon /> </SvgIcon>
                    </Tooltip>
                    :
                    <Tooltip title="Copy" >
                    <SvgIcon onClick={() => copyClipboard(0, blockContent)} >  <ContentCopyIcon />   </SvgIcon>
                    </Tooltip>
                )
              }>
                <Typography variant="h5">Abc</Typography>
              </CardHeader>
              <CardContent sx={{ pt: 0 }}>
                <Box
                  sx={{
                    display: "flex",
                    direction: "column",
                    justifyContent: "center",
                    mb: 2,
                    mt: "50px",
                  }}
                >
                </Box>
                <Box sx={{
                  position:'absolute',
                  top:'0',
                  right:'0'
                }}>
                <SvgIcon>
                  <ContentCopyIcon />
                </SvgIcon>
                </Box>
                
                <pre style={{ overflowX: 'auto', whiteSpace: 'pre-wrap', color: 'orange' }}>
                  <code style={styles.code}>
                    {blockContent}
                  </code>
                </pre>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
      <Stack sx={
        {
          width: '100%'
        }
      } justifyContent={'center'}>
        <Grid>
          <Grid justifyContent={'center'} item xs={12} md={12} sm={12}>
            <Card>
              <CardHeader title={'Ifame Integration Code - If interegated code if getting affected by native code use iframe to isolate code env'} variant="h4" action={
                (
                  isCopied[0] ?
                  <Tooltip title="Copied!" sx={{color: '#16B364', cursor:'pointer'}}>
                    <SvgIcon onClick={() => copyClipboard(0, iframeBlockContent)} sx={{ color: '#16B364' }}> <ContentCopyOutlinedIcon /> </SvgIcon>
                    </Tooltip>
                    :
                    <Tooltip title="Copy" >
                    <SvgIcon onClick={() => copyClipboard(0, iframeBlockContent)} >  <ContentCopyIcon />   </SvgIcon>
                    </Tooltip>
                )
              }>
                <Typography variant="h5">Abc</Typography>
              </CardHeader>
              <CardContent sx={{ pt: 0 }}>
                <Box
                  sx={{
                    display: "flex",
                    direction: "column",
                    justifyContent: "center",
                    mb: 2,
                    mt: "50px",
                  }}
                >
                </Box>
                <Box sx={{
                  position:'absolute',
                  top:'0',
                  right:'0'
                }}>
                <SvgIcon>
                  <ContentCopyIcon />
                </SvgIcon>
                </Box>
                
                <pre style={{ overflowX: 'auto', whiteSpace: 'pre-wrap', color: 'orange' }}>
                  <code style={styles.code}>
                    {iframeBlockContent}
                  </code>
                </pre>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
      <Stack sx={{
        width: '100%'
      }}>
        <Grid>
          <Card>
          <CardHeader title={'Floating Widget Integration Code'} variant="h4" action={
                (
                  isCopied[1] ?
                  <Tooltip title="Copied!" sx={{color: '#16B364', cursor:'pointer'}}>
                    <SvgIcon onClick={() => copyClipboard(1, floatingWidgetContent)} sx={{ color: '#16B364' }}> <ContentCopyOutlinedIcon /> </SvgIcon>
                    </Tooltip>
                    :
                    <Tooltip title="Copy">
                    <SvgIcon onClick={() => copyClipboard(1, floatingWidgetContent)} >  <ContentCopyIcon />   </SvgIcon>
                    </Tooltip>
                )
              }>
                <Typography variant="h5"></Typography>
              </CardHeader>
            <CardContent sx={{ pt: 0 }}>
              <Stack spacing={2} direction={'row'}>
                {isFloatingWidget ? getChips() : ''}
              </Stack>
              <Stack sx={{paddingTop: '10px'}}>
                {isFloatingWidget ? <pre style={{ overflowX: 'auto', whiteSpace: 'pre-wrap', color: 'orange' }}>
                  <code style={styles.code}>
                    {floatingWidgetContent}
                  </code>
                </pre>: <Chip label={'This section is available for premium member.'} deleteIcon={<DoneIcon />}
      /> }
                
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Stack>

      <Stack sx={{
        width: '100%'
      }}>
        <Grid>
          <Card>
          <CardHeader title={'Add Your Google Analytics Code'} subheader="Note: Please remove the comments from GA code enclosed by '<!-- -->' if any. Check Example for reference." variant="h4"
          action={<Button onClick={() => setIsExampleOpen(true)}>Example</Button>}>
                <Typography variant="h5"></Typography>
                
                  
                
              </CardHeader>
            <CardContent sx={{ pt: 0 }}>
              <Stack spacing={2} direction={'row'}>
                <Button onClick={() => saveGoogleAnalyticsCode()} disabled={!isEdited}>Save</Button>
              </Stack>
              <Stack sx={{paddingTop: '10px'}}>
                {isGoogleAnalytics ? <pre style={{ overflowX: 'auto', whiteSpace: 'pre-wrap', color: 'orange' }}>
                  
                  <textarea rows="10" cols="140" style={styles.code} 
                  value={googleAnalyticsCode} 
                  onChange={(e)=> updateGoogleAnalyticsCode(e.target.value)}>
  
</textarea>
                </pre>: <Chip label={'This section is available for premium member.'} deleteIcon={<DoneIcon />}
      /> }
                
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Stack>
      <GAExampleDialog open={isExampleOpen} onClose={() => setIsExampleOpen(false)}></GAExampleDialog>
    </>
  )
}


const GAExampleDialog = (props) => {
  const {open = false, onClose=() => {}} = props
  return <Dialog
  fullWidth
  maxWidth="md"
  onClose={onClose}
  open={open}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle>{'GA Code Example'}</DialogTitle>
  <DialogContent>
      <img src="/assets/cards/GAExample.png" />
  </DialogContent>
</Dialog>
}


export default IntegrationTab;
