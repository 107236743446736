import React, {useState} from 'react';
import propTypes from 'prop-types';
import CheckIcon from '@untitled-ui/icons-react/build/esm/Check';
import { Box, Button, Card, Chip, Divider, MenuItem, Stack, SvgIcon, TextField, Typography } from '@mui/material';
import apiCalls from 'src/api';
import { useAuthContext } from 'src/contexts/auth-context';
import { toast } from 'react-hot-toast';


const calculatePrice = (discount, price) => {
  return  price - Math.floor((price * discount) / 100)
}

const getDiscount = (plan)  =>{
  console.log(plan)
  if(plan.applied_discounts.length) {
    return plan.applied_discounts[0].discount
  } else {
    return 0
  }
  // let result = 0;
  // discountPrices.forEach(element => {
  //   result = element.discount
  // });
  // return result;
}

export const PricingPlan = (props) => {
  const { plan, 
    plan_type, 
    cta, 
    currency, 
    features, 
    icon, 
    name, 
    popular, 
    sx, 
    defaultDuration='1', 
    isReadonly=false, ...other } = props;

  const { user } = useAuthContext();
  const subscription = JSON.parse(localStorage.getItem("subscription"));
  const defaultPrices = plan.planprices.filter(x => x.price_type === 'default');
  const discountPrices = defaultPrices.map(x => {
        return {
          price_id: x.price_id,
          price_type: 'discounted',
          amount: calculatePrice(getDiscount(plan), x.amount),
          duration: x.duration
        }
    });
  
  const [prices, setPrices] = useState((plan.is_discount && subscription.total_subscriptions === 0) ? discountPrices : defaultPrices);
  const [durations, setDurations] = useState(['1', '6', '12'])
  const [selectedDuration, setSelectedDuration] = useState(defaultDuration);
  const selectedPrice = prices.find(x => x.duration === selectedDuration)
  const [amount, setAmount] = useState(selectedPrice ? selectedPrice.amount : 0);
  

  const getPriceId = () => {
    if(plan.is_discount && subscription.total_subscriptions === 0) {
      let price = discountPrices.find(x => x.duration === selectedDuration);
      return price.price_id;
    } else {
      let price = defaultPrices.find(x => x.duration === selectedDuration);
      return price.price_id;
    }
  }

  const getCoupon = () => {
    let discount_coupon = ''
    if(plan.is_discount && subscription.total_subscriptions === 0 && plan.applied_discounts.length) {
      discount_coupon = plan.applied_discounts[0].discount_coupon
    }
    return discount_coupon
  }

  const createClientKey = (event) => {
    event.preventDefault();
    const price_id = getPriceId();
    if(price_id) {
      const data = {
        price_id: price_id,
        id: plan.id, 
        plan_type: plan_type,
        duration: selectedDuration
      }
      apiCalls.createClientKey(data, user.id).then((resp) => {
        if(resp) {
          window.location.replace(resp.checkout_url);
        }
      }).catch(() => {
        toast.error("Something went wrong")
      });
    } else {
      toast.error("Something went wrong")
    }
    
  }

  const createClientKeyV2 = (event) => {
    event.preventDefault();
    const price_id = getPriceId();
    if(price_id) {
      const data = {
        price_id: price_id,
        id: plan.id, 
        plan_type: plan_type,
        duration: selectedDuration
      }
      let coupon = getCoupon()
      if (coupon) {
        data['discount_coupon'] = coupon;
      }
      apiCalls.createClientKeyV2(data, user.id).then((resp) => {
        if(resp) {
          window.location.replace(resp.checkout_url);
        }
      }).catch(() => {
        toast.error("Something went wrong")
      });
    } else {
      toast.error("Something went wrong")
    }
    
  }

  const changeDuration = (e)  => {
    setSelectedDuration(e.target.value);
    setAmount(prices.find(x => x.duration === e.target.value).amount);
  }

  const getDefaultPrice = () => {
    return defaultPrices.find(x => x.duration === selectedDuration).amount
  }

  // const getDiscount = ()  =>{
  //   console.log(plan)
  //   if(plan.applied_discounts.length) {
  //     return plan.applied_discounts[0].discount
  //   } else {
  //     return 0
  //   }
  //   // let result = 0;
  //   // discountPrices.forEach(element => {
  //   //   result = element.discount
  //   // });
  //   // return result;
  // }

 
  
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '550px',
        ...sx
      }}
      {...other}>
      <Box sx={{ p: 3 }}>
      <Box sx={{display: 'flex'}}>
      <Box
          sx={{
            height: 52,
            width: 52,
          }}
        >
          {icon} 
        </Box>
        {plan.is_discount && subscription.total_subscriptions === 0 ? <Chip label={`${getDiscount(plan)}% discount applied for new users`}></Chip>: ''}
      </Box>

        <Box sx={{ display: 'flex' }}>
          <Typography variant="h4" >
            {currency}
            {amount}
          </Typography>
          { plan.is_discount && subscription.total_subscriptions === 0 ? <Typography
            color="text.secondary" sx={{textDecoration: 'line-through'}}>
              {getDefaultPrice()}
            </Typography>: ''}
          <Typography
            color="text.secondary"
            sx={{
              alignSelf: 'flex-end',
              ml: 1
            }}
            variant="subtitle2"
          >
            / {selectedDuration === '1' ? 'month': `${selectedDuration} months` }
          </Typography>
          <TextField 
            label={'Select Duration'}
            sx={{marginLeft: 'auto', minWidth:'30%'}} 
            select 
            value={selectedDuration} onChange={changeDuration}
            disabled={isReadonly}>
          {durations.map((option) => (
                  <MenuItem
                      key={option}
                      value={option}
                  >
                      {option}
                  </MenuItem>
              ))}
          </TextField>
        </Box>
        <Typography
          sx={{ mt: 2 }}
          variant="h6"
        >
          {name}
        </Typography>
        <Typography
          color="text.secondary"
          sx={{ mt: 2 }}
          variant="body2"
        >
          {plan.description}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          p: 3
        }}
      >
        <Stack
          spacing={2}
          sx={{ flexGrow: 1 }}
        >
          {plan.features.split(',').map((feature) => (
            <Stack
              alignItems="center"
              direction="row"
              spacing={1}
              key={feature}
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <SvgIcon color="success">
                <CheckIcon />
              </SvgIcon>
              <Typography
                sx={{ fontWeight: 500 }}
                variant="body2"
              >
                {feature}
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6
          }}
        >
          {isReadonly ? '' :
          <Button
            fullWidth
            variant={popular ? 'contained' : 'outlined'}
            onClick={createClientKeyV2}
          >
            {cta}
          </Button> }
        </Box>
      </Box>
    </Card>
  );
};

PricingPlan.propTypes = {
  cta: propTypes.string.isRequired,
  currency: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  features: propTypes.array.isRequired,
  icon: propTypes.any.isRequired,
  name: propTypes.string.isRequired,
  popular: propTypes.bool,
  price: propTypes.string.isRequired,
  sx: propTypes.object,
  plan_type: propTypes.string.isRequired
};
