import React from "react";
import { Grid } from "@mui/material";
import { CreateCalculatorForm } from "../create-calculator";

const DetailsTab = (props) => {
  return (
    <>
      <Grid container spacing={3} padding={4}>
        <Grid item xs={12} md={12} sm={12}>
          <CreateCalculatorForm
            update_callback={props.update_callback}
            is_update={true}
            data={props.data}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DetailsTab;
