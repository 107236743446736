export const tokens = {
  common: {
    languageChanged: "common.languageChanged",
  },
  nav: {
    overview: "nav.overview",
    dashboard: "nav.dashboard",
    classes: "nav.classes",
    calculators: "nav.calculators",
    subscription: "nav.subscription"
  },
};
