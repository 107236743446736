import {
    Dialog, DialogContent, DialogTitle,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Stack
} from "@mui/material";
import { useEffect, useState } from "react";
import apiCalls from "src/api";

const mockData = {
    "id": "7095a514-9a79-416b-9703-3c7d5e07a526",
    "created_at": "2024-02-21T05:32:53.024763Z",
    "updated_at": "2024-02-21T05:42:23.059619Z",
    "user_endpoint": "/retirement-calculator",
    "start_time": "2024-02-21T05:32:50.750000Z",
    "stop_time": "2024-02-21T05:42:20.750000Z",
    "calculation_request_count": 1,
    "field_values": [
        {
            "amount": 100,
            "Muni_(%)": 0,
            "REIT_(%)": 0,
            "US_Agg_(%)": 0,
            "Year_Range": 55,
            "EM_Bond_(%)": 0,
            "S&P_500_(%)": 0,
            "Em_Stock_(%)": 0,
            "Eur_Stock_(%)": 0,
            "High Yield (%)": 0,
            "Commodities_(%)": 0,
            "Annual_Investment": 1
        }
    ],
    "calculator": "fe045d56-362d-418d-b6eb-fb34697454d8",
    "domain_info": "3686eed2-c108-4f0b-be1b-d6cba93ce4a7"
}
const extractFieldValuesHeaders = (field_values_obj) => {
    if (field_values_obj) {
        return Object.keys(field_values_obj).map(x => x.split('_').join(' '))
    }
    return []
}

const sanitaizeHeaders = (field_values_obj) => {
    if (field_values_obj) {
        Object.keys(field_values_obj).forEach(x => {
            field_values_obj[x.split('_').join(' ')] = field_values_obj[x]
        })
    }
    return field_values_obj
}

const createHeadingRow = (headers) => {
    return headers.map(x => (
        <TableCell>
            {x}
        </TableCell>
    ))

}

const extractFieldValues = (field_values, headers) => {
    return field_values.toReversed().map((usage) => { 
        usage = sanitaizeHeaders(usage)
        
        
        return (
        
        <TableRow hover>
            {headers.map(key =>{ 
                return(
                <TableCell>
                    {key != 'Event Timestamp' ? usage[key] || '0' : usage[key] || 'NA' }
                </TableCell> ) }
                )}
        </TableRow>
    )
                }
    )

}
const createFieldValus = (data) => {
    const fieldValues = [];
    data.forEach(element => {
        fieldValues.push(...element.field_values);
    });
    return fieldValues;
}

const sumCalculateRequestCount = (data) => {
    return data.reduce((accu, curr) => accu + curr.calculation_request_count, 0)
}

const UsageDetailsDialog = (props) => {
    const { onClose = () => { }, open = false, domainId = "" } = props;
    const [data, setData] = useState([{field_values:[{}]}])
    const [fieldValues, setFieldValues] = useState(mockData.field_values)
    const [headers, setHeaders] = useState([])
    // useState(extractFieldValuesHeaders(data[0].field_values[0])) 
    const [calculateRequestCount, setCalculateRequestCount] = useState(0)
    
    useEffect(() =>{
        if(domainId) {
            apiCalls.getUsageDetails(domainId).then(resp => {
                if(resp.status < 300) {
                    if(resp.data.results.length) {
                        setData(resp.data.results);
                        setHeaders(
                            extractFieldValuesHeaders(resp.data.results[0].field_values[0])
                        );
                        setFieldValues(createFieldValus(resp.data.results));
                        setCalculateRequestCount(sumCalculateRequestCount(resp.data.results));
                    }
                }
            }).catch(err => {
                console.log(err);
            })
        }
    }, [domainId])
    
    return <Dialog
        fullWidth
        maxWidth="md"
        onClose={onClose}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle>{'Calculator Usage Details'}</DialogTitle>
        <DialogContent>
            <Table sx={{ minWidth: 700 }}>
                <TableHead>
                    <TableRow>
                        {createHeadingRow(headers)}
                    </TableRow>
                </TableHead>
                <TableBody>
                        {extractFieldValues(fieldValues, headers)}
                </TableBody>
            </Table>
        </DialogContent>
    </Dialog>
}

export default UsageDetailsDialog