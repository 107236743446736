import { Stack, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useRef } from "react";

const ColorPalette = (props) => {
    const { selectedBackgroundColor, updateColorHandler, title = 'Color' } = props;
    const styles = {
        colorPocket: {
            height: '20px',
            width: '20px',
            borderRadius: '5px',
            cursor:'pointer'
        },
        colorInput: {
            WebkitAppearance: 'none',
            borderRadius: '5px',
            border:'none',
            width: '25px',
            height: '25px',
        }
    }
    const colorPicker = useRef()
    const colorsList = ['#12467B', '#32383E', '#7D1212', '#0A470A', '#492B08']
    const getDesiredColorStyle = (bgColor) => {
        const newStyle = { ...styles.colorPocket };
        newStyle['backgroundColor'] = bgColor
        return newStyle
    }

    const openColorPicker = (event) => {
        console.log(colorPicker)
        event.preventDefault();
        colorPicker.current.click()
    }

    const changeColor = (color) => {
        updateColorHandler(color);
        colorPicker.current.value = color
    }
    
    return <>
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Stack direction={'row'} spacing={3}>
                    <Typography variant="secondary" >
                        {title}
                    </Typography>
                    <input ref={colorPicker} type="color"  onChange={(event) => updateColorHandler(event.target.value)} 
                    name={'heading_' + title } style={styles.colorInput} value={selectedBackgroundColor} />
                    {/* <div onClick={openColorPicker} style={getDesiredColorStyle(selectedBackgroundColor)}>

                    </div> */}
                </Stack>
                 
            </AccordionSummary>
            <AccordionDetails>
                
                <Stack sx={{ width: '100%' }} direction="row" spacing={3}>
                    {colorsList.map(x => {
                        return <div key={x} onClick={() => changeColor(x) } style={getDesiredColorStyle(x)}>

                        </div>
                    })}
                </Stack>
            </AccordionDetails>
        </Accordion>

    </>
}

export default ColorPalette;

