import {
    Stack,
    Button, 
    Card, 
    Typography,
    Table,
    TableHead,
    TableRow,
    TableBody,
    Divider, 
    TableCell,
    TablePagination,
    Checkbox,   
    Link,
    Switch
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormik } from "formik";
// import CalculatorIcon from "src/icons/untitled-ui/duocolor/calculator-icon";
import * as Yup from 'yup';
import { lazy } from "react";
import { useEffect, useState } from "react";
import apiCalls from "src/api";
import { toast } from 'react-hot-toast';
import ColorPalette from "./color-palette";
import { useParams } from "react-router";
import UsageDetailsDialog from "./usage-details-dialog";
import { Scrollbar } from "src/components/scrollbar";



const CalculatorUsage = (props) => {
    const mockData = {
        "id": "3686eed2-c108-4f0b-be1b-d6cba93ce4a7",
        "created_at": "2024-02-21T05:32:47.204538Z",
        "updated_at": "2024-02-21T05:32:47.204560Z",
        "calculator": "fe045d56-362d-418d-b6eb-fb34697454d8",
        "ip_address": "127.0.0.1",
        "domain_name": "localhost:8000",
        "geolocation": {
            "query": "127.0.0.1",
            "status": "fail",
            "message": "reserved range"
        },
        "device_type": "Desktop",
        "browser_type": "Chrome",
        "browser_version": "119.0.0",
        "user_email": "sankalpbhojwani@gmail.com"
    }
    const [domainInfo, setDomainInfo] = useState([])
    const [selectedDomain, setSelectedDomain] = useState("");
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const {calculatorId } = props;
    const [count, setCount] = useState(0);
    const [next, setNext] = useState('');
    const [previous, setPrevious] = useState(null);
    const [page, setPage] = useState(0);
    
    useEffect(() => {
        if(calculatorId) {
            getCalculatorUsage(false)
        }
        
    }, [calculatorId])

    const getCalculatorUsage = (newPage) => {
        let res;
        let isPrevious = newPage < page ? true : false;
        if(next && !isPrevious) {
          res = apiCalls.getDomainInfo(calculatorId, next); 
        } else if (isPrevious) {
          res = apiCalls.getDomainInfo(calculatorId, previous);
        }else {
          res =  apiCalls.getDomainInfo(calculatorId);
        }

        res.then(resp => {
            if(resp.status < 300) {
                if(resp.data.results.length) {
                    setDomainInfo(resp.data.results)
                }
                
                setNext(resp.data.next);
                setPrevious(resp.data.previous)
                setCount(resp.data.count)
                if(typeof newPage !== 'boolean') {
                  setPage(newPage);

                }
            }
        }).catch(err => {
            console.log(err);
        })
    }
    return (
        <>
            {/* <Stack
                sx={{
                    padding: "20px",
                }}
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={1}
            >
                <Typography variant="h4">Calculator Usage</Typography>
            </Stack>
            <Divider /> */}
            <Stack
                sx={{
                    padding: "20px",
                    width: "100%"
                }}
                spacing={3}
            >
                <Card>
                    <Divider />
                    {/* <Stack
                        alignItems="center"
                        direction="row"
                        flexWrap="wrap"
                        gap={2}
                        sx={{ p: 3 }}
                    >
                        <OutlinedInput
                            placeholder="Search customers"
                            startAdornment={
                                <InputAdornment position="start">
                                    <SvgIcon>
                                        <SearchMdIcon />
                                    </SvgIcon>
                                </InputAdornment>
                            }
                            sx={{ flexGrow: 1 }}
                        />
                    </Stack> */}
                    <Scrollbar>
                        <Table sx={{ minWidth: 700 }}>
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell padding="checkbox">
                                        <Checkbox />
                                    </TableCell> */}
                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Domain Name</TableCell>
                                    <TableCell align="center">Device Type</TableCell>
                                    <TableCell align="center">Browser Type</TableCell>
                                    <TableCell align="center">Browser Version</TableCell>
                                    <TableCell align="center">IP Address</TableCell>
                                    <TableCell align="center">Calculation Count</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {domainInfo.map((domain) => {
                                    return (
                                        <TableRow hover key={domain.id}>
                                            {/* <TableCell padding="checkbox">
                                                <Checkbox />
                                            </TableCell> */}
                                            <TableCell align="center">
                                                {domain.user_email}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Stack alignItems="center" direction="row" spacing={1}>
                                                    <div>
                                                        <Link color="inherit" variant="subtitle2">
                                                            {domain.domain_name}
                                                        </Link>
                                                    </div>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="center">{domain.device_type}</TableCell>
                                            <TableCell align="center">
                                                {domain.browser_type}
                                            </TableCell>
                                            <TableCell align="center">
                                                {domain.browser_version}
                                            </TableCell>
                                            <TableCell align="center">
                                                {domain.ip_address}
                                            </TableCell>
                                            <TableCell align="center">
                                                {domain.calculate_request_count}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    outline="secondary"
                                                    onClick={() => {
                                                        setSelectedDomain(domain.id); 
                                                        setIsOpenDialog(true); }}
                                                >Details</Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Scrollbar>
                    <TablePagination
                        component="div"
                        count={count}
                        onPageChange={(event, newPage) => { getCalculatorUsage(newPage) }}
                        onRowsPerPageChange={() => { }}
                        page={page}
                        rowsPerPage={10}
                        rowsPerPageOptions={[10]}
                    />
                </Card>
            </Stack>
            <UsageDetailsDialog 
                    onClose={() => setIsOpenDialog(false)} 
                    open={isOpenDialog} 
                    domainId={selectedDomain}>
            </UsageDetailsDialog>
        </>
    )
}



const styles = {
    colorInput: {
        WebkitAppearance: 'none',
        border: 'none',
        width: '32px',
        height: '32px',
    }
}

export default CalculatorUsage;