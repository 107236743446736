import { lazy, Suspense } from "react";
import { Layout as AuthLayout } from "src/layouts/auth/classic-layout";
import { Layout as DashboardLayout } from "src/layouts/dashboard";
import { ProfileForm } from "src/pages/auth/profileForm";
import { ProfileView } from "src/pages/auth/profileView";
import { CreateCalculatorForm } from "src/pages/calculators/create-calculator";
// import { ItemList } from "src/pages/calculators/item-list";
import { CustomTablePage } from "src/pages/calculators/index";
import NotFound from "src/pages/error/404";
import { PricingPlansPage } from "src/pages/plans/plan-page";
import CalculatorDetails from "src/pages/calculators/details/index";
import { paths } from "src/paths";
import RetirementCalculatorView from "src/pages/calculators/widgets/retirement-calculator/RetirementCalculatorView";
import { getCachedUser } from "src/utils/cache";
import { Navigate } from 'react-router-dom';

const IndexPage = lazy(() => import("src/pages/index"));
const JwtLoginPage = lazy(() => import("src/pages/auth/login"));
const JwtRegisterPage = lazy(() => import("src/pages/auth/register"));
const JwtForgotPasswordPage = lazy(() =>
  import("src/pages/auth/forgotPassword")
);
const JwtResetPasswordPage = lazy(() => import("src/pages/auth/resetPassword"));
const PrivacyPolicy = lazy(() =>
  import("src/pages/terms-n-conditions/privacy-policy")
);
const RetirementCalculator = lazy(() => import("src/pages/calculators/widgets/retirement-calculator/RetirementCalculator"));
const InvestmentCalculator = lazy(() => import("src/pages/calculators/widgets/investment-calculator/InvestmentCalculator"));
const FloatingWidget = lazy(() => import('src/pages/calculators/integration-components/calculator-floating-widget.js'))
const ArchivedCalulators = lazy(() => import('src/pages/calculators/archives'));
const SubscriptionsConfig = lazy(() => import('src/pages/subscriptions/index'));
const CustomersList = lazy(() => import('src/pages/customers/index'));
const FreeusersList = lazy(() => import('src/pages/customers/free-user-list'));
const UpgradeSuccess = lazy(() => import('src/pages/auth/upgrade-success'));
const CustomeSignUpLinkManagement = lazy(() => import('src/pages/subscriptions/custom-signup-links-managment'));

const user = getCachedUser();

const publicRoutes = [
  ...[
    {
      path: paths.login,
      element: <JwtLoginPage />,
    },
    {
      path: paths.register,
      element: <JwtRegisterPage />,
    },
    {
      path: paths.forgotPassword,
      element: <JwtForgotPasswordPage />,
    },
    {
      path: paths.resetPassword,
      element: <JwtResetPasswordPage />,
    },
    {
      path: paths.pricingPlans,
      element: <PricingPlansPage />
    },
    {
      path: paths.privacyPolicy,
      element: <PrivacyPolicy />,
    },
    { path: '/', element: <Navigate to="/dashboard" /> },
  ].map((item) => ({
    ...item,
    element: <AuthLayout>{item.element}</AuthLayout>,
  })),
  [

  ],
];

let privateRoutes = [
  {
    path: paths.index,
    index: true,
    element: <IndexPage />,
  },
  {
    path: paths.profile,
    element: <ProfileView />
  },
  {
    path: paths.editProfile,
    element: <ProfileForm />
  },
  {
    path: paths.calculators,
    element: <CustomTablePage />
  },
  {
    path: paths.createCalculator,
    element: <CreateCalculatorForm />
  },
  {
    path: paths.calculatorsDetails,
    element: <CalculatorDetails />
  },
  {
    path: paths.calculatorsArchive,
    element: <ArchivedCalulators />
  },
  {
    path: paths.upgradePlanSuccess,
    element: <UpgradeSuccess />
  },
  {
    path: paths.subscriptionsConfig,
    element: <SubscriptionsConfig />
  },
  {
    path: paths.customersList,
    element: <CustomersList />
  },
  {
    path: paths.FreeUsersList,
    element: <FreeusersList />
  },
  {
    path: paths.customSignupLinks,
    element: <CustomeSignUpLinkManagement />
  },
  { path: '/', element: <Navigate to="/dashboard" /> },
];

privateRoutes = privateRoutes.map((item) => ({
  ...item,
  element: (
    <DashboardLayout>
      <Suspense>{item.element}</Suspense>
    </DashboardLayout>
  ),
}));

const FullPageLayoutPages = [
  {
    path: paths.retirementCalculatorWidget,
    element: <RetirementCalculator />
  },
  {
    path: paths.investmentCalculatorWidget,
    element: <InvestmentCalculator />
  },
  {
    path: paths.floatingWidget,
    element: <FloatingWidget />
  },
  {
    path: paths.retirementCalculator,
    element: <RetirementCalculatorView />
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export const routes = [
  ...publicRoutes,
  ...privateRoutes,
  ...FullPageLayoutPages,
];
