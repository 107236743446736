import Lock01Icon from '@untitled-ui/icons-react/build/esm/Lock01';
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  Chip,
  DialogActions
} from '@mui/material';
import { SeverityPill } from 'src/components/severity-pill';
import { useAuthContext } from 'src/contexts/auth-context';
import { useNavigate } from 'react-router';
import { format } from "date-fns";
import { paths } from 'src/paths';
import { useState } from 'react';
import apiCalls from 'src/api';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { PricingPlan } from '../plans/pricing-plan';

export const ProfileView = () => {
    const { user } = useAuthContext();
    const subscription = JSON.parse(localStorage.getItem('subscription'));
    const navigate = useNavigate();
    const redirectToEdit = (event) => { 
      event.preventDefault(); navigate(paths.editProfile)
    }
    const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] = useState(false)
    const [proratedCost, setProratedCost] = useState(0);
    const [premiumPlan, setPremiumPlan] = useState(null);
    const icon =<SvgIcon > <AutoGraphIcon /> </SvgIcon>
    
    
    const getProratedAmount = () => {
      fetchPlans();
      apiCalls.getProratedAmount(subscription.recent_subscription.id)
        .then((resp) => {
          if(resp.status < 300) {
            setProratedCost(resp.data.data.prorated_cost);
            setIsUpgradePlanDialogOpen(true);
          }
        }).catch(err => {

        })
    }

    const createPlanUpgradeSession = () => {
      apiCalls.createPlanUpgradeSession(subscription.recent_subscription.id, {}).then(resp => {
        if("checkout_url" in resp) {
          window.location.replace(resp.checkout_url);
        }

      }).catch(err => {
        console.log(err)
      })
    }

    const fetchPlans = () => {
        apiCalls.getPlans(user.id).then((resp) => {
            if (resp.status < 300) {
              resp.data.results.forEach(x => {
                if(x.name.toUpperCase() === "PREMIUM") {
                  setPremiumPlan(x);
                }
              })
            }
        }).catch(err => {
            console.log(err)
        });
    }
    return (<>
    <Box
          sx={{
            backgroundColor: (theme) => theme.palette.mode === 'dark'
              ? 'neutral.800'
              : 'neutral.100',
            p: 3
          }}
        >
          <Card>
            <CardHeader />
            <Stack direction="row" justifyContent="space-between"
              spacing={1}
              sx={{ pl: 1, pr:3, pb:2 }}>
            <Typography variant='h5' maxWidth={'50%'} >
                Profile Details
            </Typography>
                <a href="#" onClick={redirectToEdit}>
                <SvgIcon>
                    <EditIcon/>
                </SvgIcon>
                </a>
              </Stack>
            <Divider />
            <Table>
              <TableBody  sx={{
                padding:'20px',
                margin: '20px'
            }}>
              <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">
                      First Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                    >
                      {user.first_name}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">
                      Last Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                    >
                      {user.last_name}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">
                      Email
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row">
                    <Typography
                      color="text.secondary"
                      variant="body2"   
                    >
                      {user.email}
                    </Typography>
                    <SeverityPill color="success">
                        verified
                    </SeverityPill>
                    </Stack>
                    
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">
                      Phone
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                    >
                      {user.phone_number ? user.phone_number.country_code + ' ' + user.phone_number.number : '-' }
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">
                      Date Joined
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                    >
                      {format(new Date(user.date_joined), 'dd-MM-yyyy')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </Box>
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.mode === 'dark'
              ? 'neutral.800'
              : 'neutral.100',
            p: 3
          }}
        >
          <Card>
            <CardHeader />
            <Stack direction="row" justifyContent="space-between"
              spacing={1}
              sx={{ pl: 1, pr:3, pb:2 }}>
            <Typography variant='h5' maxWidth={'50%'} >
                Current Subscription Details
            </Typography>
              </Stack>
            <Divider />
            <Table>
              <TableBody  sx={{
                padding:'20px',
                margin: '20px'
            }}>
              <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">
                      Plan Type
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                    >
                      {subscription.recent_subscription ? subscription.recent_subscription.plan_type.toUpperCase() : ''}
                    </Typography>
                  </TableCell>
                  <TableCell>{subscription.recent_subscription && subscription.recent_subscription.plan_type.toUpperCase() === 'BASE' ?
                      <Chip sx={{floatLeft: "auto"}} label="Updgrade Plan"
                      onClick={getProratedAmount}
                      variant="outlined"
                      ></Chip>: ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">
                      Plan Status
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                    >
                    <SeverityPill color={subscription.recent_subscription  && subscription.recent_subscription.is_active ? 'success': 'warning'}>
                    {subscription.recent_subscription  && subscription.recent_subscription.is_active ? 'Active': 'Inactive'}
                    </SeverityPill>
                      
                    </Typography>
                  </TableCell>
                </TableRow>
              <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">
                      Amount
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                    >
                      {subscription.recent_subscription  && subscription.recent_subscription.current_plan_price.amount} 
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">
                      Currency Type
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                    >
                      {subscription.recent_subscription  && subscription.recent_subscription.transaction.currency_type.toUpperCase()} 
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle2">
                      Expire On
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                    >
                      {format(new Date(subscription.recent_subscription  && subscription.recent_subscription.expiration_date), 'dd-MM-yyyy')}
                    </Typography>
                  </TableCell>
                </TableRow>
                
              </TableBody>
            </Table>
          </Card>
        </Box>
        <Dialog open={isUpgradePlanDialogOpen} onClose={() => setIsUpgradePlanDialogOpen(false)}>
          <DialogTitle>Upgrade plan to PREMIUM</DialogTitle>
          <DialogContent>
          <Typography sx={{color: "red"}}>
            Heads up! 
            </Typography>
            <Typography variant='p' >
            Your recent upgrade to our Premium Plan comes with a host of enhanced features and capabilities to better cater to your needs. As a result, there will be a prorated cost adjustment of approximately ${proratedCost} in your upcoming invoice.
            </Typography> 
            {premiumPlan ? <PricingPlan  plan={premiumPlan} 
            plan_type={premiumPlan.name} 
            defaultDuration={subscription.recent_subscription.current_plan_price.duration} 
            isReadonly={true} 
            cta={'Subscribe'} 
            currency={'$'} 
            description={'Subscription Details'}
            features={['Investment Calculator']} icon={icon} name={premiumPlan.name.toUpperCase()} popular={true} price={'500'} /> : ""}
          
            
          </DialogContent>
          <DialogActions>
            <Button onClick={createPlanUpgradeSession} >Continue</Button>
            <Button onClick={() => setIsUpgradePlanDialogOpen(false)} color="error">Cancel</Button>
          </DialogActions>
        </Dialog>
    </>
      )
};
