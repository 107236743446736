import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack,
    TextField,
    Button
} from '@mui/material'; 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import apiCalls from 'src/api';
import { useState } from 'react';
import toast from "react-hot-toast";
const ReferenceToken = (props) => {
    const [referenToken, setReferenToken] = useState('')
    const redeemReferenceToken = (event) => {
        event.preventDefault();
        const data = {
            token : referenToken
        }        
        apiCalls.redeemReferenceToken(data).then((resp) => {
            if(resp) {
                window.location.replace(resp.checkout_url);
            }
        }).catch((e) => {
            toast.error(e.data.message)
        });
        
      }
    return <Accordion>
    <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
    >
        Reference Token
    </AccordionSummary>
    <AccordionDetails>
        <Stack justifyContent={'flex-start'} alignContent={'center'} spacing={3}>
            <TextField
                fullWidth
                helperText={""}
                label="Enter the reference token"
                name="title"
                onChange={(e) => {setReferenToken(e.target.value)}}
            >
            </TextField>
            {/* <input type="color" onChange={fieldsTextColor} name="fieldsColor" style={styles.colorInput} /> */}
            <Button
                variant={'contained'}
                onClick={redeemReferenceToken}
                >
                Apply
                </Button> 
        </Stack>
    </AccordionDetails>
</Accordion>
}

export default ReferenceToken;