import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { useGlobalContext } from 'src/contexts/global-context';

export const Seo = (props) => {
  const { title } = props;
  const { globalConfig } = useGlobalContext();

  const fullTitle = title
    ? title + globalConfig.name?globalConfig.name:' | Democratyz'
    : globalConfig.name?globalConfig.name:'Democratyz';

  return (
    <Helmet>
      <title>
        {fullTitle}
      </title>
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string
};
