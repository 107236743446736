import { Dialog, DialogContent, DialogTitle, 
    TextField, Typography, Stack, Button, DialogActions, Grid, Switch } from "@mui/material";
import { useEffect, useState } from "react";

const UpdateFieldDialog = (props) => {
    const { onClose, open = false, onSave, isHideable=false, isEditField=true } = props;
    const [newTitle, setNewTitle] = useState('');
    const flag = ("style" in props.fieldData && 
                'display' in props.fieldData.style && 
                props.fieldData.style["display"] === "none");
    const [isHidden, setIsHidden] = useState(flag);
    const handleHiddenChange = (e) => {
        setIsHidden(!isHidden)
    }
    useEffect(() => {
        setNewTitle(props.fieldData.label);
        setIsHidden(flag)
    }, [open]);
    return <Dialog
        fullWidth
        maxWidth="md"
        onClose={onClose}
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle>{'Update Field Title'}</DialogTitle>
        <DialogContent>
            <Grid container p={2} >
                <Grid item xs={6} md={6}>
                <Typography variant="h6" sx={{padding:2}} >
                    {props.fieldData.label}
                </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                <TextField 
                label="Change Title"
                name={props.fieldData.id}                
                value={newTitle}
                onChange={(event) => setNewTitle(prev => event.target.value)}
                disabled={!isEditField}  />
                </Grid>
            </Grid>
            {props.fieldData.isHideable && isHideable ?  
            <Grid container p={2}>
                <Grid item xs={6} md={6}>
                <Typography variant="h6" sx={{padding: 2}}  >
                    Hide Field
                </Typography>
                </Grid>
                <Grid item  xs={6} md={6}>
                <Switch
                    checked={isHidden}
                    color="primary"
                    edge="start"
                    name="isVerified"
                    onChange={handleHiddenChange}
                    value={1000}
                    
                />
                </Grid>
                
            </Grid> : '' }
            
        </DialogContent>
        <DialogActions>
            <Button variant="outline"  onClick={() => {
                setNewTitle('');
                onClose();}}>Cancel</Button>
            <Button variant="contained" onClick={() => {
                    const newValues = {...props.fieldData};
                    if(props.fieldData.isHideable && isHideable) {
                        if(!("style" in newValues)) {
                            newValues["style"] = {'display': isHidden ? 'none' : 'block' };
                        } else {
                            newValues["style"]['display'] = isHidden ? 'none' : 'block';
                        }
                    }
                    newValues['label'] = newTitle;
                    onSave(newValues)
                }}>Save</Button>
        </DialogActions>
    </Dialog>
}



export default UpdateFieldDialog;