import {
  Grid,
  Stack,
  Box,
  TextField,
  MenuItem,
  Button,
  Card,
  CardHeader,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useFormik } from "formik";
// import CalculatorIcon from "src/icons/untitled-ui/duocolor/calculator-icon";
import * as Yup from "yup";
import { lazy } from "react";
import { useEffect, useState } from "react";
import apiCalls from "src/api";
import { toast } from "react-hot-toast";
import ColorPalette from "./color-palette";
import { useParams } from "react-router";
import UpdateFieldDialog from "./update-field-dialog";
const RetirementCalculator = lazy(() =>
  import("../widgets/retirement-calculator/RetirementCalculator")
);
const InvestmentCalculator = lazy(() =>
  import("../widgets/investment-calculator/InvestmentCalculator")
);
const HomeLoanCalculator = lazy(() =>
  import("../widgets/home-loan-calculator/HomeLoanCalculator")
);
// import CalculatorWidget from "../widgets/CalculatorWidget";
const BusinessLoanCalculator = lazy(() =>
  import("../widgets/business-loan-calculator/BusinessLoanCalculator")
);
const AdvisoryFeeComparisonCalculator = lazy(() =>
  import("../widgets/fee-comparison-calculator/AdvisoryFeeComparisonCalculator")
);
const defaultConfigObj = {
  democratyz_calculator_heading: {
    color: "#212529",
    "font-size": "32px",
    "font-weight": "700",
    "line-height": "1.1em",
    "margin-bottom": "20px",
  },
  democratyz_calculator_fields: {
    color: "#e71010",
    "font-size": "12px",
    "font-weight": 300,
    "letter-spacing": ".1rem",
  },
  democratyz_calculator_container: {
    "border-color": "#92a8d1",
    "font-family": '"Helvetica Neue", Helvetica, Arial, sans-serif',
  },
  democratyz_calculator_gradient: {
    "background-image": "linear-gradient(160deg,#eda384,#f40f7e)",
  },
};

const cssStringToObject = (cssString) => {
  const cssObject = {};

  // Regular expression to extract key-value pairs from the CSS string
  const regex = /\.([a-zA-Z0-9_-]+)\s*{([^}]+)}/g;

  let match;
  while ((match = regex.exec(cssString)) !== null) {
    const className = match[1].trim();
    const styleString = match[2].trim();

    const stylePairs = styleString.split(";").filter(Boolean);
    const styles = {};

    stylePairs.forEach((pair) => {
      const [property, value] = pair.split(":").map((part) => part.trim());
      styles[property] = value;
    });

    cssObject[className] = styles;
  }

  return cssObject;
};

const removeImportantString = (str) => {
  return str.replaceAll("!important", "");
};

const CustomizeTab = (props) => {
  const {
    data,
    css_code,
    user_permissions = [],
    updateConfig = () => {},
  } = props;
  const isHideable =
    -1 < user_permissions.findIndex((x) => "can_hide_fields" === x);
  const isEditField =
    -1 < user_permissions.findIndex((x) => "can_edit_field_labels" === x);
  const defaultConfig = cssStringToObject(removeImportantString(css_code));
  // const [defaultConfig, setDefaultConfig] = useState(css_code ? cssStringToObject(css_code) : defaultConfigObj);

  const [containerConfig, setContainerConfig] = useState(
    defaultConfig.democratyz_calculator_container ||
      defaultConfigObj.democratyz_calculator_container
  );
  const [maxHeightValue, setMaxHeightValue] = useState(200);
  // const [maxWidthValue, setMaxWidthValue] = useState(200)
  const [headingConfig, setHeadingConfig] = useState(
    defaultConfig.democratyz_calculator_heading ||
      defaultConfigObj.democratyz_calculator_heading
  );
  const [feildsConfig, setFieldsConfig] = useState(
    defaultConfig.democratyz_calculator_fields ||
      defaultConfigObj.democratyz_calculator_fields
  );
  const [sliderConfig, setSliderConfig] = useState(
    defaultConfig.democratyz_calculator_gradient ||
      defaultConfigObj.democratyz_calculator_gradient
  );
  const [selectedHeadingColor, setSelectedHeadingColor] = useState(
    headingConfig["color"]
  );
  const [selectedFieldColor, setSelectedFieldColor] = useState(
    feildsConfig["color"]
  );
  const [selectedBorderColor, setSelectedBorderColor] = useState(
    containerConfig["border-color"]
  );
  const [selectedHeadingFontSize, setSelectedHeadingFontSize] = useState(
    headingConfig["font-size"].slice(0, 2)
  );
  const [selectedFeildsFontSize, setSelectedFeildsFontSize] = useState(12);
  const sliderColors = sliderConfig["background-image"].split(",");
  const [sliderRange, setSliderRange] = useState({ min: 10, max: 55 });
  const [isSlider, setIsSlider] = useState(false);
  const [selectedSliderColor, setSelectedSliderColor] = useState({
    colorOne: sliderColors[1] || "#eda384",
    colorTwo: sliderColors[2].split([")"])[0] || "#f40f7e",
  });
  // const [selectedCalulateBtnColor, setSelectedCalulateBtnColor] = useState({'colorOne': '#eda384',
  // 'colorTwo':'f40f7e'});
  const [isGradient, setIsGradient] = useState(null);
  const [selectedFont, setSelectedFont] = useState("");
  const fontStyles = {
    "Helvetica Neue": '"Helvetica Neue", Helvetica, Arial, sans-serif',
    "Times New Roman": '"Times New Roman", Times, serif',
    Arial: "Arial, sans-serif",
    "Courier New": '"Courier New", Courier, monospace',
    Georgia: "Georgia, serif",
    Verdana: "Verdana, Geneva, sans-serif",
    "Lucida Console": '"Lucida Console", Monaco, monospace',
    "Palatino Linotype": '"Palatino Linotype", "Book Antiqua", Palatino, serif',
    "Adobe Garamond Pro": '"Adobe Garamond Pro", Garamond, serif',
    Calibri: "Calibri, sans-serif",
    Cambria: "Cambria, serif",
    Impact: "Impact, Charcoal, sans-serif",
    Tahoma: "Tahoma, Geneva, sans-serif",
    "Arial Black": '"Arial Black", Gadget, sans-serif',
    "Comic Sans MS": '"Comic Sans MS", cursive, sans-serif',
  };
  const initialValues = {
    font: "",
    btn_border_color: "",
    btn_text_color: "",
    btn_spl_color: "",
    btn_submit_color: "",
    max_width: "",
    max_height: "",
  };
  const validationSchema = Yup.object({
    font: Yup.string().max(255),
    btn_border_color: Yup.string().max(255),
    btn_text_color: Yup.string().max(255),
    btn_spl_color: Yup.string().max(255),
    btn_submit_color: Yup.string().max(255),
    max_width: Yup.string().max(255),
    max_height: Yup.string().max(255),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {},
  });
  const templateOptions = [
    { id: 0, name: "Roberto" },
    { id: 1, name: "Italic" },
  ];
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [labelConfig, setlabelConfig] = useState(
    data.config.label_config ||
      data.calculator.default_config.label_config ||
      []
  );
  const [selectedFieldData, setSelectedFieldData] = useState({ label: "" });

  const setSliderValues = () => {
    if ("config" in data && "slider_range" in data["config"]) {
      setSliderRange({ 
        min: data["config"]["slider_range"]["min"], 
        max: data["config"]["slider_range"]["max"] 
      })
    } 
  }

  const updateHeadingColor = (color) => {
    const headings = document.getElementsByClassName(
      "democratyz_calculator_heading"
    );
    for (let i = 0; i < headings.length; i++) {
      headings[i].style.setProperty("color", color, "important");
    }
    setHeadingConfig((prev) => {
      prev["color"] = `${color} !important`;
      return prev;
    });
    setSelectedHeadingColor(color);
  };

  const updateBorderColor = (color) => {
    const headings = document.getElementsByClassName(
      "democratyz_calculator_container"
    );
    for (let i = 0; i < headings.length; i++) {
      headings[i].style.borderColor = color;
      headings[i].style.border = "solid";
    }
    setContainerConfig((prev) => {
      prev["border-color"] = `${color} !important`;
      return prev;
    });
    setSelectedBorderColor(color);
  };

  const updateHeadingFontSize = (event) => {
    const headings = document.getElementsByClassName(
      "democratyz_calculator_heading"
    );
    for (let i = 0; i < headings.length; i++) {
      if (event.target.value) {
        headings[i].style.setProperty(
          "font-size",
          `${event.target.value}px`,
          "important"
        );
      } else {
        headings[i].style.fontSize = "32px";
      }
    }
    setHeadingConfig((prev) => {
      prev["font-size"] = `${event.target.value}px !important`;
      return prev;
    });
    setSelectedHeadingFontSize(event.target.value);
  };

  const updateFieldsFontSize = (event) => {
    const fields = document.getElementsByClassName(
      "democratyz_calculator_fields"
    );
    for (let i = 0; i < fields.length; i++) {
      if (event.target.value) {
        fields[i].style.setProperty(
          "font-size",
          `${event.target.value}px`,
          "important"
        );
      } else {
        fields[i].style.fontSize = "12px";
      }
    }
    setFieldsConfig((prev) => {
      prev["font-size"] = `${event.target.value}px !important`;
      return prev;
    });
    setSelectedFeildsFontSize(event.target.value);
  };

  const fieldsTextColor = (color) => {
    const fields = document.getElementsByClassName(
      "democratyz_calculator_fields"
    );
    for (let i = 0; i < fields.length; i++) {
      fields[i].style.setProperty("color", color, "important");
    }
    setFieldsConfig((prev) => {
      prev["color"] = `${color} !important`;
      return prev;
    });
    setSelectedFieldColor(color);
  };

  const convertObjectToCssClass = (d) => {
    let result = "";
    for (let key of Object.keys(d)) {
      result += `${key}:${d[key]};`;
    }
    return result;
  };
  // TODO Optimize Code to use one method only
  const updateGradientColorOne = (color) => {
    const cls = `linear-gradient(160deg,${color},${selectedSliderColor["colorTwo"]})`;
    if (!isGradient) {
      const elements = document.getElementsByClassName(
        "democratyz_calculator_special_btn"
      );
      setIsGradient(elements);

      for (let i = 0; i < elements.length; i++) {
        // elements[i].classList.remove('democratyz_calculator_gradient');
        elements[i].style.setProperty("backgound-image", cls, "important");
        // elements[i].style.backgroundImage = cls;
        // elements[i].style.backgoundColor = cls;
      }
    } else {
      const elements = isGradient;
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.setProperty("background-image", cls, "important");
        // elements[i].style.backgroundImage = cls;
        // elements[i].style.backgoundColor = cls;
      }
    }

    setSelectedSliderColor((prev) => {
      prev["colorOne"] = color;
      return prev;
    });
    setSliderConfig((prev) => {
      prev["background-image"] = cls + " !important";
      return prev;
    });
  };

  const updateGradientColorTwo = (color) => {
    const cls = `linear-gradient(160deg,${selectedSliderColor["colorOne"]},${color})`;
    if (!isGradient) {
      const elements = document.getElementsByClassName(
        "democratyz_calculator_special_btn"
      );
      setIsGradient(elements);
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove("democratyz_calculator_gradient");
        elements[i].style.setProperty("background-image", cls, "important");
      }
    } else {
      const elements = isGradient;
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.setProperty("background-image", cls, "important");
      }
    }
    setSelectedSliderColor((prev) => {
      prev["colorTwo"] = color;
      return prev;
    });
    setSliderConfig((prev) => {
      prev["background-image"] = cls + " !important";
      return prev;
    });
  };

  const updateFontStyle = (newFont) => {
    const containerElement = document.getElementsByClassName(
      "democratyz_calculator_container"
    );
    for (let i = 0; i < containerElement.length; i++) {
      containerElement[i].style.setProperty(
        "font-family",
        newFont,
        "important"
      );
      // containerElement[i].style.fontFamily = `${newFont} !important`
    }

    setContainerConfig((prev) => {
      prev["font-family"] = `${newFont} !important`;
      return prev;
    });
    setSelectedFont(newFont);
  };

  const saveSpecs = () => {
    let con = data.config.label_config || data.calculator.default_config;
    if ("slider_range" in con && sliderRange.max - sliderRange.min < 0) {
      toast.error("Invalid slider range");
      return;
    }
    const classes = {
      democratyz_calculator_heading: headingConfig,
      democratyz_calculator_fields: feildsConfig,
      democratyz_calculator_container: containerConfig,
      democratyz_calculator_gradient: sliderConfig,
    };
    let config = "";
    for (let key of Object.keys(classes)) {
      config += `.${key} { ${convertObjectToCssClass(classes[key])} }`;
    }
    const reqBody = {
      calculator: data.calculator.id,
      customer: data.customer,
      title: data.title,
      config: { ...data.config },
    };
    reqBody.config["cssCode"] = config;
    let prev_config =
      data.config.label_config || data.calculator.default_config;
    if ("slider_range" in prev_config) {
      let slider_range = prev_config["slider_range"];
      slider_range["min"] = sliderRange.min;
      slider_range["max"] =  sliderRange.max;
      reqBody.config["slider_range"] = slider_range;
    }
    apiCalls.updateCalculator(reqBody, data.customer, data.id).then((resp) => {
      if (resp.status < 300) {
        toast.success("Changes saved successfully!");
        updateConfig(reqBody.config);
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  const checkNumber = (s) => {
    const regex = /^[0-9]+$/;
    return regex.test(s);
  };

  const updateMaxHeight = (event) => {
    let val = parseInt(event.target.value);
    let isOutSideRange = !(200 <= val && val <= 700);
    if (!checkNumber(event.target.value)) {
      toast.error(
        "Invalid Input for height, Please provide a number between 200 and 700"
      );
      return;
    }
    setMaxHeightValue(event.target.value);
    if (isOutSideRange) {
      toast.error(
        "Invalid Input for height, Please provide a number between 200 and 700"
      );
      setMaxHeightValue(200);
    }

    // const containerElement = document.getElementsByClassName('democratyz_calculator_container');
    // for(let i = 0; i < containerElement.length; i++) {
    //     containerElement.style.maxHeight = `${event.target.value}px`;
    // }

    setMaxHeightValue(parseInt(event.target.value));
  };
  const updateMaxWidth = (event) => {
    const containerElement = document.getElementsByClassName(
      "democratyz_calculator_container"
    );
    containerElement.style.maxWidth = `${event.target.value}px`;
  };

  useEffect(() => {
    let currentKey = Object.keys(fontStyles).find(
      (key) => containerConfig["font-family"] === fontStyles[key]
    );
    const sliderColors = sliderConfig["background-image"].split(",");
    setSelectedFont(fontStyles[currentKey]);
    setTimeout(() => {
      setSelectedSliderColor((prev) => {
        prev["colorOne"] = sliderColors[1];
        prev["colorTwo"] = sliderColors[2].split([")"])[0];
        return prev;
      });
      const headings = document.getElementsByClassName(
        "democratyz_calculator_heading"
      );
      for (let i = 0; i < headings.length; i++) {
        headings[i].innerHTML = data.title;
        let computedStyle = window.getComputedStyle(headings[i]);
        let fontSize = computedStyle.getPropertyValue("font-size");
        let color = computedStyle.getPropertyValue("color");
        setSelectedHeadingFontSize(parseInt(fontSize.split("px")[0]));
        setSelectedHeadingColor(rgbToHex(color));
      }
      const fields = document.getElementsByClassName(
        "democratyz_calculator_fields"
      );
      if (fields.length) {
        let computedStyle = window.getComputedStyle(fields[0]);
        let color = computedStyle.getPropertyValue("color");
        let fontSize = computedStyle.getPropertyValue("font-size");
        setSelectedFeildsFontSize(parseInt(fontSize.split("px")[0]));
        setSelectedFieldColor(rgbToHex(color));
      }
      if ("label_config" in data["config"]) {
        for (let i = 0; i < data["config"]["label_config"].length; i++) {
          let id = data["config"]["label_config"][i]["id"];
          const ele = document.getElementById(id);
          const labelStyle = data["config"]["label_config"][i]["style"];
          // TODO
          const label = ele.querySelector("label");
          label.innerText = data["config"]["label_config"][i]["label"];
          for (let key of Object.keys(labelStyle)) {
            if (ele) {
              ele.style[key] = labelStyle[key];
            }
          }
        }
      }
      let config = data.config.label_config || data.calculator.default_config;
      if ("slider_range" in config) {
        let index = user_permissions.findIndex((x) => "can_edit_slider" === x);
        setIsSlider(index > -1);
        setSliderRange(config.slider_range);
        const sliders = document.getElementsByClassName(config.slider_range.id);
        const label_min = document.getElementsByClassName(
          config.slider_range.label_min
        );
        const label_max = document.getElementsByClassName(
          config.slider_range.label_max
        );
        for (let x of label_min) {
          x.innerText = config.slider_range.min;
        }
        for (let x of label_max) {
          x.innerText = config.slider_range.max;
        }
        for (let slider of sliders) {
          if (slider) {
            slider.setAttribute("min", config.slider_range.min);
            slider.setAttribute("max", config.slider_range.max);
          } else {
            console.log(slider);
          }
        }
      }
      setSliderValues();
    }, 100);
  }, []);

  const sliderMinChange = (e) => {
    let newVal = parseInt(e.target.value) | 0;
    console.log(e.target.value, newVal);
    setSliderRange({ min: newVal, max: sliderRange.max });
    let config = data.config.label_config || data.calculator.default_config;
    if ("slider_range" in config) {
      setIsSlider(true);
      const sliders = document.getElementsByClassName(config.slider_range.id);
      const label_min = document.getElementsByClassName(
        config.slider_range.label_min
      );
      for (let x of label_min) {
        x.innerText = newVal;
      }
      for (let slider of sliders) {
        if (slider) {
          slider.setAttribute("min", newVal);
          slider.setAttribute("max", sliderRange.max);
          updateDiff(newVal, sliderRange.max);
        }
      }
    }
  };

  const sliderMaxChange = (e) => {
    let newVal = parseInt(e.target.value) | 0;
    setSliderRange({ min: sliderRange.min, max: newVal });
    let config = data.config.label_config || data.calculator.default_config;
    if ("slider_range" in config) {
      setIsSlider(true);
      const sliders = document.getElementsByClassName(config.slider_range.id);
      const label_max = document.getElementsByClassName(
        config.slider_range.label_max
      );
      for (let x of label_max) {
        x.innerText = newVal;
      }
      for (let slider of sliders) {
        if (slider) {
          slider.setAttribute("min", sliderRange.min);
          slider.setAttribute("max", newVal);
          updateDiff(sliderRange.min, newVal);
        }
      }
    }
  };

  const updateDiff = (min, max) => {
    const diff_year_element = document.getElementById("output_range");
    diff_year_element.innerHTML = `${max - min} years`;
  };

  const updateFieldTitle = (newValue) => {
    if (newValue.label) {
      const config = { ...data.config };
      const index = labelConfig.findIndex((x) => x.id === newValue.id);
      const newLabelConfig = [...labelConfig];
      newLabelConfig[index] = newValue;
      config["label_config"] = newLabelConfig;
      apiCalls
        .patchCalculatorField(data.customer, data.id, { config })
        .then((resp) => {
          toast.success("Value Updated Successfully!");
          const ele = document.getElementById(newValue.id);
          const label = ele.querySelector("label");
          label.textContent = newValue.label;
          if ("style" in newValue) {
            for (let key of Object.keys(newValue.style)) {
              ele.style[key] = newValue.style[key];
            }
          }
          setSelectedFieldData(newValue);
          setlabelConfig(newLabelConfig);
          setIsDialogOpen(false);
          updateConfig(config);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Invalid input for title");
    }
  };

  function rgbToHex(rgb) {
    var hex = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    function hexify(x) {
      return ("0" + parseInt(x).toString(16)).slice(-2);
    }
    return "#" + hexify(hex[1]) + hexify(hex[2]) + hexify(hex[3]);
  }

  return (
    <>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={8} md={8} sm={8}>
          <Card>
            <CardHeader>Calculator Layout</CardHeader>
            {/* <CalculatorWidget /> */}
            <Stack
              sx={{
                maxHeight: "100vh",
                scroll: "xAxis",
              }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {"default_config" in data.calculator ? (
                data.calculator["default_config"]["component"] ===
                "RetirementCalculator" ? (
                  <RetirementCalculator
                    css_code={css_code}
                    accessKey={data.id}
                  />
                ) : data.calculator["default_config"]["component"] ===
                  "InvestmentCalculator" ? (
                  <InvestmentCalculator
                    css_code={css_code}
                    accessKey={data.id}
                  />
                ) : data.calculator["default_config"]["component"] ===
                  "HomeLoanCalculator" ? (
                  <HomeLoanCalculator css_code={css_code} accessKey={data.id} />
                ) : data.calculator["default_config"]["component"] ===
                  "BusinessLoanCalculator" ? (
                  <BusinessLoanCalculator
                    css_code={css_code}
                    accessKey={data.id}
                  />
                ) : data.calculator["default_config"]["component"] ===
                  "AdvisoryFeeComparisonCalculator" ? (
                  <AdvisoryFeeComparisonCalculator
                    css_code={css_code}
                    accessKey={data.id}
                  />
                ) : (
                  "Failed Loading Calculator"
                )
              ) : (
                "Invalid Calculator type"
              )}
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={4} md={4} sm={4}>
          <Box>
            <form onSubmit={formik.handleSubmit} {...props}>
              <Stack spacing={2}>
                <Stack sx={{ width: "100%" }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      Calculator Heading
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack spacing={3}>
                        <TextField
                          fullWidth
                          error={!!(formik.touched.font && formik.errors.font)}
                          label="Select Heading Font Size"
                          name="calculator"
                          onBlur={formik.handleBlur}
                          onChange={updateHeadingFontSize}
                          select
                          value={selectedHeadingFontSize}
                        >
                          {[
                            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
                            33, 34,
                          ].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                        <ColorPalette
                          style={{ width: "100%" }}
                          updateColorHandler={updateHeadingColor}
                          selectedBackgroundColor={selectedHeadingColor}
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Stack>

                {data.calculator["default_config"]["component"] ===
                "RetirementCalculator" ? (
                  <Stack>
                    <ColorPalette
                      style={{ width: "100%" }}
                      updateColorHandler={updateBorderColor}
                      selectedBackgroundColor={selectedBorderColor}
                      title={"Border Color Text"}
                    />
                  </Stack>
                ) : (
                  ""
                )}

                <Stack sx={{ width: "100%" }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      Calculator Fields
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack spacing={3}>
                        <TextField
                          error={
                            !!(
                              formik.touched.btn_border_color &&
                              formik.errors.btn_border_color
                            )
                          }
                          fullWidth
                          helperText={
                            formik.touched.btn_border_color &&
                            formik.errors.btn_border_color
                          }
                          label="Select Fields Font Size"
                          name="title"
                          onBlur={formik.handleBlur}
                          onChange={updateFieldsFontSize}
                          select
                          value={selectedFeildsFontSize}
                        >
                          {[
                            4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                            18, 19,
                          ].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                        {/* <input type="color" onChange={fieldsTextColor} name="fieldsColor" style={styles.colorInput} /> */}
                        <ColorPalette
                          style={{ width: "100%" }}
                          updateColorHandler={fieldsTextColor}
                          selectedBackgroundColor={selectedFieldColor}
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Stack>

                <Stack sx={{ width: "100%" }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      Special Buttons
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack spacing={3}>
                        <ColorPalette
                          style={{ width: "100%" }}
                          updateColorHandler={updateGradientColorOne}
                          selectedBackgroundColor={
                            selectedSliderColor["colorOne"]
                          }
                          title="Color One"
                        />
                        <ColorPalette
                          style={{ width: "100%" }}
                          updateColorHandler={updateGradientColorTwo}
                          selectedBackgroundColor={
                            selectedSliderColor["colorTwo"]
                          }
                          title="Color Two"
                        />
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Stack>
                {/* <Stack sx={{ width: '100%' }}>
                                  <Accordion>
                                      <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1a-content"
                                          id="panel1a-header"
                                      >
                                          Calculate Button
                                      </AccordionSummary>
                                      <AccordionDetails>
                                          <Stack spacing={3}>
                                              <ColorPalette style={{ width: '100%' }}
                                                  updateColorHandler={updateGradientColorOne}
                                                  selectedBackgroundColor={selectedCalulateBtnColor['colorOne']}
                                                  title="Color One" />
                                              <ColorPalette style={{ width: '100%' }}
                                                  updateColorHandler={updateGradientColorTwo}
                                                  selectedBackgroundColor={selectedCalulateBtnColor['colorTwo']}
                                                  title="Color Two" />
                                          </Stack>
                                      </AccordionDetails>
                                  </Accordion>
  
  
                              </Stack> */}

                {/* <TextField
                                  error={!!(formik.touched.btn_border_color && formik.errors.btn_border_color)}
                                  fullWidth
                                  helperText={formik.touched.btn_border_color && formik.errors.btn_border_color}
                                  label="Special Border Color"
                                  name="title"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik.values.font}
                              />
                              <TextField
                                  error={!!(formik.touched.btn_border_color && formik.errors.btn_border_color)}
                                  fullWidth
                                  helperText={formik.touched.btn_border_color && formik.errors.btn_border_color}
                                  label="Submit Button Color"
                                  name="title"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik.values.font}
                              />  <TextField
                                  fullWidth
                                  label="Max Height"
                                  name="title"
                                  onBlur={formik.handleBlur}
                                  onChange={updateMaxHeight}
                                  min={200}
                                  max={700}
                                  value={maxHeightValue}
                              /> 
                              <TextField
                                  error={!!(formik.touched.btn_border_color && formik.errors.btn_border_color)}
                                  fullWidth
                                  helperText={formik.touched.btn_border_color && formik.errors.btn_border_color}
                                  label="Max Width"
                                  name="title"
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={formik.values.font}
                              />*/}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    Select Font
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack spacing={3}>
                      <TextField
                        error={!!(formik.touched.font && formik.errors.font)}
                        fullWidth
                        label="Select Font"
                        name="calculator"
                        onBlur={formik.handleBlur}
                        onChange={(event) =>
                          updateFontStyle(event.target.value)
                        }
                        select
                        value={selectedFont}
                      >
                        {Object.keys(fontStyles).map((option) => (
                          <MenuItem key={option} value={fontStyles[option]}>
                            <span style={{ fontFamily: fontStyles[option] }}>
                              {option}
                            </span>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
                <Stack sx={{ width: "100%" }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      Fields
                    </AccordionSummary>
                    <AccordionDetails>
                      <Stack spacing={3}>
                        {labelConfig.map((option) => (
                          <Button
                            key={option.id}
                            onClick={() => {
                              setSelectedFieldData({ ...option });
                              setIsDialogOpen(true);
                            }}
                          >
                            <span>{option.label}</span>
                          </Button>
                        ))}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Stack>
                {isSlider ? (
                  <Stack sx={{ width: "100%" }}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        Slider
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack spacing={3}>
                          <Typography variant="label">Min:</Typography>
                          <input
                            onChange={sliderMinChange}
                            type="text"
                            value={sliderRange.min}
                          />
                          <Typography variant="label">Max:</Typography>

                          <input
                            onChange={sliderMaxChange}
                            type="text"
                            value={sliderRange.max}
                          />
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </Stack>
                ) : (
                  ""
                )}

                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="flex-end"
                  spacing={1}
                >
                  <Button type="submit" variant="contained" onClick={saveSpecs}>
                    Save
                  </Button>
                </Stack>
              </Stack>
            </form>
          </Box>
        </Grid>
      </Grid>
      {selectedFieldData.label ? (
        <UpdateFieldDialog
          fieldData={selectedFieldData}
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onSave={(updatedValue) => updateFieldTitle(updatedValue)}
          isHideable={isHideable}
          isEditField={isEditField}
        />
      ) : (
        ""
      )}
      {/* data.calculator['default_config']['component'] === 'RetirementCalculator' && */}
    </>
  );
};

const styles = {
  colorInput: {
    WebkitAppearance: "none",
    border: "none",
    width: "32px",
    height: "32px",
  },
};

export default CustomizeTab;
