import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SvgIcon } from "@mui/material";

import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import CalculatorIcon from "src/icons/untitled-ui/duocolor/calculator-icon";
import CreditCard01 from "src/icons/untitled-ui/duocolor/credit-card-01";
import User01 from "src/icons/untitled-ui/duocolor/users-03";

import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import { useAuthContext } from "src/contexts/auth-context";

export const useSections = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const items = [{
    title: t(tokens.nav.dashboard),
    path: paths.index,
    icon: (
      <SvgIcon fontSize="small">
        <HomeSmileIcon />
      </SvgIcon>
    ),
  },
  {
    title: t(tokens.nav.calculators),
    path: paths.calculators,
    icon: (
      <SvgIcon fontSize="small">
        <CalculatorIcon />
      </SvgIcon>
    ),
  },]
  if (user.is_superuser) {
    items.push(...[
      {
        title: "Manage Subscriptions",
        path: paths.subscriptionsConfig,
        icon: (
          <SvgIcon fontSize="small">
            <CreditCard01 />
          </SvgIcon>
        ),
      },
      {
      title: "Manage Customers",
      path: paths.customersList,
      icon: (
        <SvgIcon fontSize="small">
          <User01 />
        </SvgIcon>
      ),
    }])
  }
  return useMemo(() => {
    return [
      {
        items: items,
      },
    ];
  }, [t, user]);
};
