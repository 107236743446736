import {
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import CustomizeTab from "./customize-tab";
import DetailsTab from "./details-tab";
import IntegrationTab from "./integrations-tab";
import SettingsTab from "./settings";
import { useLocation, useParams } from "react-router-dom";
import apiCalls from "src/api";
import { useAuthContext } from "src/contexts/auth-context";
import CalculatorUsage from "./calculator-usage";

const CalculatorDetails = () => {
  const { state } = useLocation();
  const [data, setData] = useState(
    state?.data || { title: "Project Name", default_data: true }
  );
  const [activeTab, setActiveTab] = useState("customize");
  const [cssCode, setCssCode] = useState("");
  const [tabs, setTabs] = useState([]);
  const [count, setCount] = useState(1);
  const params = useParams();
  const update_callback = (updatedData) => {
    setData((prev) => {
      prev["title"] = updatedData["title"];
      prev["sub_title"] = updatedData["sub_title"];
      prev["description"] = updatedData["description"];
      prev["calculator"] = updatedData["calculator"];
      return prev;
    });
  };
  const { user } = useAuthContext();
  const updateConfig = (updatedData) => {
      if ('cssCode' in  updatedData) {
        setCssCode(updatedData['cssCode'] + data.calculator.raw_code);
      }
      setData(prev => {
          prev['config'] = updatedData;
          return prev;
      });
  }
  useEffect(() => {
    setCount(count + 1);
    if ("default_data" in data) {
      apiCalls
        .getCalculators(params.id)
        .then((resp) => {
          if ("cssCode" in resp.data.config) {
            setCssCode(
              resp.data.config["cssCode"] + resp.data.calculator.raw_code
            );
          } else {
            setCssCode(resp.data.calculator.raw_code);
          }
          setData(resp.data);
        })
        .catch((err) => {
          console.log("Error Here");
          console.log(err);
        });
    } else {
      setTabs([
        {
          value: "customize",
          label: "Customize",
          component: (
            <CustomizeTab
              css_code={cssCode}
              data={data}
              user_permissions={user.user_permissions}
              updateConfig={updateConfig}
            />
          ),
        },
        {
          value: "details",
          label: "Details",
          component: (
            <DetailsTab update_callback={update_callback} data={data} />
          ),
        },
        {
          value: "integrations",
          label: "Integrations",
          component: (
            <IntegrationTab
              data={data}
              updateConfig={updateConfig}
              user_permissions={user.user_permissions}
            />
          ),
        },
        {
          value: "settings",
          label: "Settings",
          component: <SettingsTab data={data} />,
        },
        {
          value: "usageDetails",
          label: "Usage Details",
          component: (
            <CalculatorUsage css_code={cssCode} calculatorId={data.id} />
          ),
        },
      ]);
    }
  }, [data, cssCode]);

  const getActiveTabComponent = () => {
    return tabs.find((x) => x.value === activeTab).component;
  };
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  return (
    <>
      <Stack
        sx={{
          padding: "20px",
        }}
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={1}
      >
        <Typography variant="h4">{data.title}</Typography>
      </Stack>
      <Tabs
        indicatorColor="primary"
        scrollButtons="auto"
        textColor="primary"
        value={activeTab}
        sx={{ px: 3 }}
        variant="scrollable"
        onChange={handleChange}
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider />
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        gap={2}
        sx={{ p: 3 }}
      >
        {activeTab !== "" && tabs.length ? getActiveTabComponent() : ""}
      </Stack>
    </>
  );
};

export default CalculatorDetails;
