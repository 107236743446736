import React, {useState, useEffect } from "react";
import PropTypes from "prop-types";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import { toast } from "react-hot-toast";
import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    Divider,
    IconButton,
    InputAdornment,
    MenuItem,
    Stack,
    SvgIcon,
    TextField,
    Typography,
    Button
} from "@mui/material";
import apiCalls from "src/api";
import { useNavigate } from "react-router";
// import { CreateCalculatorForm } from "./create-calculator";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useAuthContext } from "src/contexts/auth-context";
import { paths } from "src/paths";

export const CreateCalculatorModal = (props) => {
    const navigate = useNavigate();
    const { onClose, open = false, ...other } = props;
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useAuthContext()
    const [templateOptions, setTemplateOptions] = useState([{
        name: 'Select Template',
        id: 0
    }]);
    const getTemplates = async () => {
        const res = await apiCalls.getCoreCalculators(user.id);
        if (res.status < 300) {
            setTemplateOptions((prevState) => {
                return [...prevState, ...res.data]
            })
        }
    }
    const initialValues = {
        title: '',
        calculator: 0,
        submit: null
    }
    const validationSchema = Yup.object({
        title: Yup.string().max(255).required("Title is required"),
        calculator: Yup.number().min(1).required("Type is required"),
    });
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, helpers) => {
            try {
                // NOTE: Make API request
                apiCalls.createCalculator(values, user.id).then((res) => {
                    toast.success('Calculator created successfully');
                    const data = res;
                    navigate(`/calculators/${data.id}/details/`);
                }).catch((err) => { console.log(err) });
            } catch (err) {
                console.error(err);
                toast.error('Something went wrong, please try after some time')
                helpers.setStatus({ success: false });
                helpers.setErrors({ submit: err.message });
                helpers.setSubmitting(false);
            }
        }
    });
    const localOnClose = () => {
        onClose();
    };

    useEffect(() => {
        if (templateOptions.length < 2) {
            getTemplates()
        }
    }, [templateOptions])

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            onClose={localOnClose}
            open={open}
            {...other}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={3}
                sx={{
                    px: 3,
                    py: 2,
                }}
            >
                <Typography variant="h6">Create Calculator</Typography>
                <IconButton color="inherit" onClick={localOnClose}>
                    <SvgIcon>
                        <XIcon />
                    </SvgIcon>
                </IconButton>
            </Stack>
            <DialogContent>
                <Box>
                    <form
                        onSubmit={formik.handleSubmit}
                        {...props}>
                        <Stack spacing={3}>
                            <TextField
                                error={!!(formik.touched.title && formik.errors.title)}
                                fullWidth
                                helperText={formik.touched.title && formik.errors.title}
                                label="Title"
                                name="title"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.title}
                                required
                            />
                            <TextField
                                error={!!(formik.touched.calculator && formik.errors.calculator)}
                                fullWidth
                                label="Calculator Template"
                                name="calculator"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                select
                                value={formik.values.calculator}
                            >
                                {templateOptions.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Stack
                                alignItems="center"
                                direction="row"
                                justifyContent="flex-end"
                                spacing={1}
                            >
                                <Button color="inherit" onClick={localOnClose}>
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                >
                                    Create
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </Box>
                {isLoading && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 3,
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}

            </DialogContent>
        </Dialog>
    );
};

CreateCalculatorModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
};
