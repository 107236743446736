import {
  Stack,
  Divider,
  Typography,
  Switch,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Button,
} from "@mui/material";
import { Container } from "@mui/system";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import apiCalls from "src/api";
const SettingsTab = (props) => {
  const { data } = props;
  const [isActive, setIsActive] = useState(data.is_active);
  const naivgate = useNavigate();
  const handleActiveChange = () => {
    apiCalls
      .patchCalculatorField(data.customer, data.id, { is_active: !isActive })
      .then((resp) => {
        const message = !isActive
          ? "Calculator Enabled"
          : "Calculator Disabled";
        setIsActive(!isActive);
        toast.success(message);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };
  const [isRemoved, setIsRemoved] = useState(false);
  const [removeInput, setRemoveInput] = useState("");
  const [isEmailPopup, setIsEmailPopup] = useState(data.show_email_popup);
  const handleEmailPopupChange = () => {
    apiCalls
      .patchCalculatorField(data.customer, data.id, {
        show_email_popup: !isEmailPopup,
      })
      .then((resp) => {
        const message = !isEmailPopup
          ? "Email popup to subscribe is enabled"
          : "Email popup to subscribe is disabled";
        setIsEmailPopup(!isEmailPopup);
        toast.success(message);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong!");
      });
  };
  useEffect(() => {
    // TODO Check isRemoved from backend
    setIsRemoved(false);
  }, []);
  const handleRemoveInputChange = (event) => {
    setRemoveInput(event.target.value);
  };
  const handleDeleteClick = async () => {
    if (removeInput === "remove") {
      // TODO Handle Delete Functionlity
      const resp = await apiCalls.deleteCalculator(data.id);
      if (resp.status < 300) {
        toast.success("Successfully deleted calculator!");
        setIsRemoved(true);
        naivgate("/calculators");
      } else {
        console.error("Something went wrong!", resp);
      }
    } else {
      console.error("Invalid input");
    }
  };
  return (
    <>
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <Card>
            <CardContent sx={{ pt: 0 }}>
              <Stack divider={<Divider />} spacing={3} sx={{ mt: 3 }}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Stack spacing={1}>
                    <Typography gutterBottom variant="subtitle1">
                      Email Popup
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      Toggle The Switch To {isEmailPopup ? "Disable" : "Enable"}{" "}
                      Email popup to subscribe
                    </Typography>
                  </Stack>
                  <Switch
                    checked={isEmailPopup}
                    color="primary"
                    edge="start"
                    name="isVerified"
                    onChange={handleEmailPopupChange}
                    value={1000}
                    disabled={isRemoved}
                  />
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Container>
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <Card>
            <CardContent sx={{ pt: 0 }}>
              <Stack divider={<Divider />} spacing={3} sx={{ mt: 3 }}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Stack spacing={1}>
                    <Typography gutterBottom variant="subtitle1">
                      Active
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      Toggle The Switch To{" "}
                      {isActive ? "Enable Calculator" : "Disable Calculator"}
                    </Typography>
                  </Stack>
                  <Switch
                    checked={isActive}
                    color="primary"
                    edge="start"
                    name="isVerified"
                    onChange={handleActiveChange}
                    value={1000}
                    disabled={isRemoved}
                  />
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Container>
      <Container maxWidth="lg">
        <Stack spacing={3}>
          <Card>
            <CardContent sx={{ pt: 0 }}>
              <Stack divider={<Divider />} spacing={3} sx={{ mt: 3 }}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Stack>
                    <Typography gutterBottom variant="subtitle1">
                      Remove
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                      Please type 'remove' in the input box to delete the
                      calculator
                    </Typography>
                  </Stack>
                  {!isRemoved ? (
                    <>
                      <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        spacing={3}
                      >
                        <TextField
                          sx={{
                            maxHeight: "40.5px",
                          }}
                          color="primary"
                          edge="start"
                          name="isRemoved"
                          value={removeInput}
                          onChange={handleRemoveInputChange}
                        />
                        <Button
                          sx={{
                            backgroundColor: "error.main",
                            "&:hover": {
                              backgroundColor: "error.dark",
                            },
                            mr: 2,
                          }}
                          variant="contained"
                          onClick={handleDeleteClick}
                        >
                          Delete
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    <Stack>
                      <Typography color="text.secondary" variant="body2">
                        Deleted
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Container>
    </>
  );
};

export default SettingsTab;
